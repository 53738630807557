import type { FormBuilderFragment } from '~/graphql/types';
import type { Issue } from '../../state/issues';
import { flatten, keys } from 'ramda';
import { isNonEmptyString } from '~/util/Validation/String';

const TITLE_CHAR_LIMIT = 33;
const SUBTITLE_CHAR_LIMIT = 70;
const localeFieldNames = ['nl', 'en'];

const messages = {
  missingName: 'Een naam is verplicht voor formulieren',
  missingDescription: 'Omschrijving is verplicht voor formulieren',
  missingLocale: 'Er is tenminste 1 taal nodig',
  overMaxTitle: `Titel mag niet meer dan ${TITLE_CHAR_LIMIT} tekens bevatten`,
  overMaxSubTitle: `Subtitle mag niet meer dan ${SUBTITLE_CHAR_LIMIT} tekens bevatten`,
};

const getIssuesForForm = (form: FormBuilderFragment): Array<Issue> =>
  flatten(
    keys(form).map((formKey): Array<Issue> => {
      switch (formKey) {
        case 'name': {
          if (isNonEmptyString(form[formKey])) {
            return [];
          }

          return [
            {
              level: 'error',
              message: messages.missingName,
              sectionId: 'settings',
              inputKey: 'name',
            },
          ];
        }
        case 'description': {
          if (isNonEmptyString(form[formKey])) {
            return [];
          }

          return [
            {
              level: 'error',
              message: messages.missingDescription,
              sectionId: 'settings',
              inputKey: 'description',
            },
          ];
        }
        case 'title': {
          return localeFieldNames.reduce((issues, lang) => {
            const title = form.title[lang];

            if (title && title.length > TITLE_CHAR_LIMIT) {
              issues.push({
                level: 'error',
                message: messages.overMaxTitle,
                sectionId: 'widget-settings',
                inputKey: `title-${lang}`,
              });
            }

            return issues;
          }, [] as Array<Issue>);
        }
        case 'subTitle': {
          return localeFieldNames.reduce((issues, lang) => {
            const subTitle = form.subTitle[lang];

            if (subTitle && subTitle.length > SUBTITLE_CHAR_LIMIT) {
              issues.push({
                level: 'error',
                message: messages.overMaxSubTitle,
                sectionId: 'widget-settings',
                inputKey: `subTitle-${lang}`,
              });
            }

            return issues;
          }, [] as Array<Issue>);
        }
        case 'style': {
          if (form.style.availableLocale.length === 0) {
            return [
              {
                level: 'error',
                message: messages.missingLocale,
                sectionId: 'settings',
              },
            ];
          }
        }

        // Fall through, we're handling these in their dedicated areas
        case 'nodes':
        case 'events':
        case 'optionLists':
        case 'id':
        case '__typename':
        case 'accountId':
        default:
          return [];
      }
    }),
  );

export default getIssuesForForm;
