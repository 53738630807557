import React from 'react';
import { TableCell } from '~/components/bad/DataTables/types.flow';
import ActionColumnCell from '~/components/bad/DataTables/components/ActionColumnCell';
import markedForDeletionContactList from '~/components/page/Contacts/util/markedForDeletionContactList';
import useErrorModal from '~/hooks/useErrorModal';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useConfirmModal from '~/hooks/useConfirmModal';
import TEST_ID from './index.testid';
import { useMarkContactForDeletionMutation } from '~/graphql/types';

const text = {
  deleteLabel: 'Verwijderen',
  deleteErrorTitle: 'Oeps!',
  deleteErrorMessage:
    'Er is iets misgegaan bij het verwijderen van het contact. Probeer het nog eens.',
  confirmModalCancelButtonText: 'Annuleren',
  confirmDeleteTitle: 'Contact verwijderen?',
  confirmDeleteMessage: 'Weet je zeker dat je dit contact wilt verwijderen?',
  confirmDeleteButton: 'Verwijderen',
};
const confirmDeleteModalText = {
  title: text.confirmDeleteTitle,
  message: text.confirmDeleteMessage,
  buttonConfirmTitle: text.confirmDeleteButton,
  buttonCancelTitle: text.confirmModalCancelButtonText,
};

type Props = {
  contactId: string;
  cell: TableCell<any>;
  onSuccessfulDelete: () => void;
};
const ContactActionColumnCell: React.FCC<Props> = ({
  contactId,
  cell,
  onSuccessfulDelete,
}) => {
  const [errorModal, showErrorModal] = useErrorModal({
    title: text.deleteErrorTitle,
    message: text.deleteErrorMessage,
  });
  const account = useCurrentAccount();

  const [showDeleteConfirmModal, setOnDeleteConfirmFunction] = useConfirmModal({
    labels: confirmDeleteModalText,
    dataTestId: TEST_ID.CONFIRM_MODAL,
    actionType: 'destructive',
  });

  const [markContactForDeletion, { loading }] =
    useMarkContactForDeletionMutation({
      onError: () => showErrorModal(),
    });

  return (
    <>
      <ActionColumnCell
        key={'contact-action-column-cell'}
        loading={loading}
        options={[
          {
            label: text.deleteLabel,
            onClick: async () => {
              const deleteFn = async () => {
                const mutationResult = await markContactForDeletion({
                  variables: {
                    accountId: account.id,
                    id: contactId,
                  },
                });
                if (isErroredDeletionResult(mutationResult)) {
                  showErrorModal();
                } else {
                  markedForDeletionContactList.writeMarkedForDeletionContact(
                    contactId,
                  );

                  onSuccessfulDelete();
                }
              };

              setOnDeleteConfirmFunction(deleteFn);
              showDeleteConfirmModal();
            },
            key: 'delete',
            type: 'DELETE',
          },
        ]}
        cell={cell}
      />
      {errorModal}
    </>
  );
};

const isErroredDeletionResult = mutationResult =>
  !mutationResult ||
  !mutationResult.data ||
  mutationResult.data.markContactForDeletion.success !== true;

export default ContactActionColumnCell;
