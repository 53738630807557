import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import BottomButtonRow from '~/components/bad/Modals/BottomButtonRow';
import Button from '~/components/atom/Button';
import useKeybinding from '~/hooks/useKeybinding';
import { Heading3 } from '~/components/atom/Typography';
import Modal from '~/components/organism/ModalsV2/Modal';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import getButtonAppearance from './utils/getButtonAppearance';

export type ActionType = 'destructive' | 'normal' | 'success';

export type Props = {
  /** Action handler. confirm: boolean. If confirm === true, action submited*/
  handleAction: (confirm: boolean) => void;

  /** Title of modal */
  title?: string;

  /** Message of modal */
  message?: string;

  /** title for confirm button */
  buttonConfirmTitle?: string;

  /** title for cancel button */
  buttonCancelTitle?: string;

  /** Hides the cancel button */
  hideCancel?: boolean;

  disabled?: boolean;
  loading?: boolean;
  actionType?: ActionType;
  children?: React.ReactNode;
};

const text = {
  defaultTitle: 'Er staan nog wijzigingen open',
  buttonConfirm: 'Wijzigingen vergeten',
  buttonCancel: 'Annuleer',
};

export const ConfirmModalContent: React.FCC<Props> = ({
  handleAction,
  title,
  message,
  buttonConfirmTitle,
  buttonCancelTitle,
  dataTestId,
  hideCancel,
  children,
  disabled,
  loading,
  actionType = 'normal',
}) => {
  const titleText = title !== undefined ? title : text.defaultTitle;
  let messageComponent: React.ReactNode | null = null;
  if (message) {
    const messageParagraphs = message.split(/\r?\n/);

    messageComponent = messageParagraphs.map((par, idx) => (
      <p key={`p-${idx}`}>{par}</p>
    ));
  }

  const onCancel = e => {
    if (e != null) {
      e.stopPropagation();
      e.preventDefault();
    }
    handleAction(false);
  };

  const onConfirm = e => {
    if (e != null) {
      e.stopPropagation();
      e.preventDefault();
    }
    handleAction(true);
  };

  let cancelButton: ReactElement<typeof Button> | null = null;
  if (hideCancel !== true) {
    cancelButton = (
      <Button
        data-testid="button-modal-cancel"
        appearance="primary"
        ghost
        label={buttonCancelTitle || text.buttonCancel}
        onClick={onCancel}
      />
    );
  }

  useKeybinding({
    keys: 'enter',
    callback: onConfirm,
  });

  useKeybinding({
    keys: 'escape',
    callback: onCancel,
  });

  return (
    <SmallModalContainer data-testid={dataTestId || 'confirm-modal'}>
      <InfoContainer>
        <Heading3 color={{ group: 'primary' }}>{titleText}</Heading3>
        {messageComponent}
        {children}
      </InfoContainer>
      <BottomButtonRow>
        {cancelButton}
        <Button
          data-testid="button-modal-yes"
          appearance={getButtonAppearance(actionType)}
          onClick={onConfirm}
          disabled={disabled || loading}
          loading={loading}
          label={buttonConfirmTitle || text.buttonConfirm}
        />
      </BottomButtonRow>
    </SmallModalContainer>
  );
};

const ConfirmModal: React.FCC<Props> = props => (
  <Overlay onClose={() => props.handleAction(false)} root="confirm-modal">
    <Modal maxWidth="500px" onClose={() => props.handleAction(false)}>
      <ConfirmModalContent {...props} />
    </Modal>
  </Overlay>
);

const InfoContainer = styled.div<{}>`
  text-align: left;
  width: 100%;
  ${({ theme }) => `
    padding: ${theme.space('xl')};
  `};
`;

const MODAL_MIN_WIDTH = 150;

const SmallModalContainer = styled.div<{}>`
  ${({ theme }) => css`
    max-width: max-content;
    min-height: ${MODAL_MIN_WIDTH}px;
    box-shadow: ${theme.getTokens().boxShadow.base};
  `};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  padding: 0;
`;

export default ConfirmModal;
