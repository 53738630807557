import {
  type FormBuilder_Label_Intl,
  FormData_TypographyType,
  type FormBuilder_ScreenNode_BlockFragment,
  FormBuilder_Ui_Alignment,
} from '~/graphql/types';
import {
  blocksMap,
  type ScreenNodeBlock_FE,
} from '../../../../../../constants';
import { v4 as uuid } from 'uuid';

const getEmptyBlock = (
  type: ScreenNodeBlock_FE,
): FormBuilder_ScreenNode_BlockFragment => {
  const key = uuid();
  const emptyLabel: FormBuilder_Label_Intl = {
    __typename: 'FormBuilder_Label_Intl',
    en: `New ${blocksMap[type].label}`,
    nl: `Nieuw ${blocksMap[type].label}`,
  };

  switch (type) {
    case 'FormData_Input_Email':
    case 'FormData_Input_Integer':
    case 'FormData_Input_Multiline':
    case 'FormData_Input_Text':
    case 'FormData_Input_Contact_Name':
    case 'FormData_Input_Contact_Phone':
      return {
        __typename: type,
        label: emptyLabel,
        key,
        required: false,
      };

    case 'FormData_Input_Contact_Email':
      return {
        __typename: type,
        label: emptyLabel,
        key,
        required: true,
      };

    case 'FormData_Select_Dropdown':
    case 'FormData_Select_MultiButton':
    case 'FormData_Select_Radio':
      return {
        __typename: type,
        key,
        optionListId: '',
        label: emptyLabel,
        required: false,
      };

    case 'FormData_UI_Image':
      return {
        __typename: 'FormData_UI_Image',
        alt: emptyLabel,
        image: { __typename: 'DHImage', s3key: '', url: '' },
        key,
        width: 800,
        alignment: FormBuilder_Ui_Alignment.Left,
      };
    case 'FormData_UI_RichText':
      return {
        __typename: 'FormData_UI_RichText',
        content: {
          __typename: 'FormBuilder_Label_Intl',
          nl: '<div>Je tekst hier</div>',
          en: '<div>Your text here</div>',
        },
        key,
        alignment: FormBuilder_Ui_Alignment.Left,
      };
    case 'FormData_UI_Typography':
      return {
        __typename: 'FormData_UI_Typography',
        content: emptyLabel,
        key,
        type: FormData_TypographyType.Heading1,
        alignment: FormBuilder_Ui_Alignment.Left,
      };

    case 'FormData_GDPR':
      return {
        __typename: 'FormData_Select_Radio',
        key,
        optionListId: '',
        label: {
          __typename: 'FormBuilder_Label_Intl',
          en: `Terms and conditions`,
          nl: `Algemene voorwaarden`,
        },
        required: true,
      };
  }
};

export default getEmptyBlock;
