import { v4 as uuid } from 'uuid';
import {
  FormBuilder_Event_MetaField,
  FormBuilder_Locale,
  FormBuilder_PrimitiveType,
  FormBuilder_Ui_Alignment,
  FormBuilderMode,
  type FormBuilderFragment,
} from '~/graphql/types';
import generateIdForEntity from '~/util/generateIdForEntity';
import type { TemplateArgs } from '.';

const getBasicTemplate = ({
  accountId,
  brandSettings,
}: TemplateArgs): FormBuilderFragment => {
  const firstNodeId = generateIdForEntity('FORMBUILDER_SCREEN_NODE');
  const secondNodeId = generateIdForEntity('FORMBUILDER_EVENT_NODE');
  const thirdNodeId = generateIdForEntity('FORMBUILDER_SCREEN_NODE');
  const defaultEventId = generateIdForEntity('FORMBUILDER_EVENT');

  const emailNodeFieldId = uuid();
  const emailEventFieldId = uuid();
  const nameEventFieldId = uuid();
  const nameNodeFieldId = uuid();

  return {
    mode: FormBuilderMode.Basic,
    accountId: accountId,
    enabled: false,
    title: {
      __typename: 'FormBuilder_Label_Intl',
      nl: 'Formulier',
      en: 'Form',
    },
    subTitle: {
      __typename: 'FormBuilder_Label_Intl',
      nl: 'Subtitle nl',
      en: 'Subtitle en',
    },
    name: 'Nieuw formulier',
    description: 'Een nieuw formulier',
    __typename: 'FormBuilder',
    id: uuid(),
    liveURL: 'not-defined-yet',

    events: [
      // All events should have default email so no need to configure it here
      {
        id: defaultEventId,
        name: 'Submit event fields',
        __typename: 'FormBuilder_Event',
        fields: [
          {
            name: 'Contact e-mail',
            key: emailEventFieldId,
            __typename: 'FormBuilder_Event_Field',

            metaField: FormBuilder_Event_MetaField.ContactEmail,
            type: {
              __typename: 'FormBuilder_Event_Field_Type_Primitive',
              type: FormBuilder_PrimitiveType.Email,
            },
          },
          {
            name: 'Voor en achternaam',
            key: nameEventFieldId,
            __typename: 'FormBuilder_Event_Field',
            metaField: FormBuilder_Event_MetaField.ContactName,
            type: {
              __typename: 'FormBuilder_Event_Field_Type_Primitive',
              type: FormBuilder_PrimitiveType.String,
            },
          },
        ],
      },
    ],
    nodes: [
      {
        __typename: 'FormBuilder_ScreenNode',
        blocks: [
          {
            __typename: 'FormData_Input_Contact_Email',
            key: emailNodeFieldId,
            label: {
              __typename: 'FormBuilder_Label_Intl',
              en: 'Email address',
              nl: 'E-mailadres',
            },
            required: true,
          },
          {
            __typename: 'FormData_Input_Contact_Name',
            key: nameNodeFieldId,
            label: {
              __typename: 'FormBuilder_Label_Intl',
              en: 'First and last name',
              nl: 'Voor en achternaam',
            },
            required: false,
          },
        ],
        id: firstNodeId,
        defaultNext: {
          __typename: 'FormBuilder_Node_DefaultNext',
          targetNodeId: secondNodeId,
        },
        name: 'Landing screen',
      },

      {
        __typename: 'FormBuilder_EventNode',
        id: secondNodeId,
        name: 'Submit event',
        formBuilderEventId: defaultEventId,
        mapping: [
          {
            key: emailEventFieldId,
            pointer: [firstNodeId, `${firstNodeId}-${emailNodeFieldId}`],
            __typename: 'FormBuilder_EventNode_Mapping',
          },
          {
            key: nameEventFieldId,
            pointer: [firstNodeId, `${firstNodeId}-${nameNodeFieldId}`],
            __typename: 'FormBuilder_EventNode_Mapping',
          },
        ],
        defaultNext: {
          __typename: 'FormBuilder_Node_DefaultNext',
          targetNodeId: thirdNodeId,
        },
      },

      {
        __typename: 'FormBuilder_ScreenNode',
        blocks: [
          {
            __typename: 'FormData_UI_RichText',
            content: {
              __typename: 'FormBuilder_Label_Intl',
              en: '<h1>Thanks for completing the form</h1><div></div>',
              nl: '<h1>Bedankt voor het invullen van het formulier</h1><div></div>',
            },
            alignment: FormBuilder_Ui_Alignment.Left,
            key: uuid(),
          },
        ],
        id: thirdNodeId,
        defaultNext: null,
        name: 'Thank you screen',
      },
    ],
    optionLists: [],
    style: {
      __typename: 'FormBuilder_Style',
      availableLocale: [FormBuilder_Locale.Nl],
      layout: {
        __typename: 'FormBuilder_Style_PageLayout_Default',
        backgroundColor: brandSettings.colors?.primary.background ?? '#163C58',
      },
      theme: {
        __typename: 'FormBuilder_Style_Theme',
        logo: {
          s3key: brandSettings.logo?.s3key ?? '',
          __typename: 'DHImage',
          url: brandSettings.logo?.url ?? '',
        },
        logoLink: brandSettings.logo?.s3key ? brandSettings.url : undefined,
        colors: {
          __typename: 'WidgetSettingsStyles',
          primary: {
            __typename: 'WidgetSettingsStyleConfigColorPair',
            background: brandSettings.colors?.primary.background ?? '',
            color: brandSettings.colors?.primary.color ?? '',
          },
          secondary: {
            __typename: 'WidgetSettingsStyleConfigColorPair',
            background: brandSettings.colors?.secondary.background ?? '',
            color: brandSettings.colors?.secondary.color ?? '',
          },
        },
      },
    },
  };
};

export default getBasicTemplate;
