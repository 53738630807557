import React from 'react';

import { TableCell } from '~/components/bad/DataTables/types.flow';

import {
  useDeleteFlowV2Mutation,
  useUpdateFlowBlueprintDetailsMutation,
} from '~/graphql/types';
import ActionColumnCell, {
  ActionListOption,
} from '~/components/bad/DataTables/components/ActionColumnCell';
import useConfirmModal from '~/hooks/useConfirmModal';
import { navigate } from '@gatsbyjs/reach-router';
import TEST_ID from './FlowActionColumnCell.testid';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import Catalog from '~/Catalog';
import getFlowCreatedDate from '~/components/page/Automation/v2/util/getFlowCreatedDate';
import { useSetRecoilState } from 'recoil';
import flowSettings from '../../v2/state/flowSettings';
import useSessionHydration from '~/hooks/useSessionHydration';
import useAvailableEmailsToSendFrom from '~/hooks/useAvailableEmailsToSendFrom';

const text = {
  deactivateLabel: 'Deactiveer',
  activateLabel: 'Activeer',
  copyLabel: 'Kopieer flow',
  deleteLabel: 'Verwijderen',
  deleteErrorMessage:
    'Er is iets misgegaan bij het verwijderen van de flow. Probeer het nog eens.',
  confirmModalTitle: 'Waarschuwing',
  confirmModalMessage:
    'Contacten die zich in deze flow bevinden zullen uit de flow worden verwijderd.',
  confirmModalConfirmButtonText: 'Flow uitzetten',
  confirmModalCancelButtonText: 'Annuleren',
  confirmDeleteTitle: 'Flow verwijderen?',
  confirmDeleteButton: 'Verwijderen',
  oneFlowInstanceExists:
    'Let op; er zit nog 1 contact in de flow. Deze wordt uit de flow verwijderd.',
  moreThanOneFlowInstanceExists: runningFlowInstances =>
    `Let op; er zitten nog ${runningFlowInstances} contacten in de flow. Deze worden uit de flow verwijderd.`,
};
const confirmDisableModalText = {
  title: text.confirmModalTitle,
  message: text.confirmModalMessage,
  buttonConfirmTitle: text.confirmModalConfirmButtonText,
  buttonCancelTitle: text.confirmModalCancelButtonText,
};
const confirmDeleteModalText = {
  title: text.confirmDeleteTitle,
  buttonConfirmTitle: text.confirmDeleteButton,
  buttonCancelTitle: text.confirmModalCancelButtonText,
};

type Props = {
  flowId: string;
  cell: TableCell<any>;
  enabled: boolean;
  flowName: string;
  onSuccessfulDelete: () => void;
  onSuccessfulEnabledChange: (newStatus: boolean) => void;
  flowDescription?: string;
  runningFlowInstances: number;
};
const FlowActionColumnCell: React.FC<Props> = ({
  flowId,
  cell,
  flowName,
  flowDescription = '',
  onSuccessfulDelete,
  onSuccessfulEnabledChange,
  enabled,
  runningFlowInstances,
}) => {
  const addToast = useAddToast();
  const availableEmailsToSendFrom = useAvailableEmailsToSendFrom();
  const [, refetchSessionHydration] = useSessionHydration();

  const account = useCurrentAccount();
  const setFlowSettings = useSetRecoilState(flowSettings);

  const [showDisableConfirmModal, setOnDisableConfirmFunction] =
    useConfirmModal({
      labels: confirmDisableModalText,
      dataTestId: TEST_ID.CONFIRM_MODAL,
      actionType: 'normal',
    });

  let message = '';
  if (runningFlowInstances > 0) {
    if (runningFlowInstances === 1) {
      message = text.oneFlowInstanceExists;
    } else {
      message = text.moreThanOneFlowInstanceExists(runningFlowInstances);
    }
  }

  const [showDeleteConfirmModal, setOnDeleteConfirmFunction] = useConfirmModal({
    labels: {
      ...confirmDeleteModalText,
      message,
    },
    dataTestId: TEST_ID.CONFIRM_MODAL,
    actionType: 'destructive',
  });

  const [deleteFlowV2, { loading: deleteLoadingV2 }] =
    useDeleteFlowV2Mutation();

  const [updateFlowBlueprintDetails] = useUpdateFlowBlueprintDetailsMutation();

  const updateOption = {
    label: enabled ? text.deactivateLabel : text.activateLabel,
    key: 'update-status',
    onClick: () => {
      const saveFunction = () =>
        updateFlowBlueprintDetails({
          variables: {
            accountId: account.id,
            id: flowId,
            update: {
              enabled: !enabled,
            },
          },
        }).then(({ data, errors }) => {
          // Here we check if we need to show the error notification

          if (errors && errors.length > 0) {
            addToast([
              formatToastMessage(
                Catalog.genericUnknownErrorMessageShort,
                'danger',
              ),
            ]);
            return;
          }

          if (availableEmailsToSendFrom.length === 0) {
            void refetchSessionHydration();
          }

          if (data) {
            onSuccessfulEnabledChange(!enabled);
            return;
          }
        });

      if (enabled === true) {
        // Show warning when attempting to turn off
        setOnDisableConfirmFunction(saveFunction);
        showDisableConfirmModal();
      } else {
        void saveFunction();
      }
    },
  };

  const options: Array<ActionListOption> = [
    {
      label: text.deleteLabel,
      onClick: (_rowId: string) => {
        const deleteFn = () =>
          deleteFlowV2({
            variables: {
              accountId: account.id,
              id: flowId,
            },
          }).then(({ data, errors }) => {
            if (errors && errors.length > 0) {
              addToast([formatToastMessage(text.deleteErrorMessage, 'danger')]);
              return;
            }
            if (data) {
              return onSuccessfulDelete();
            }
          });
        setOnDeleteConfirmFunction(deleteFn);
        showDeleteConfirmModal();
      },
      key: 'delete',
      type: 'DELETE',
    },
    updateOption,
    {
      label: text.copyLabel,
      onClick: (_rowId: string) => {
        const copiedFlowName = `Kopie van ${flowName} gemaakt op ${getFlowCreatedDate()}`;

        setFlowSettings(prev => ({
          ...prev,
          flowName: copiedFlowName,
          flowDescription: flowDescription,
        }));

        return navigate(
          `/-/automation/flows/builder-v2/create/${flowId}?name=${encodeURIComponent(
            copiedFlowName,
          )}&description=${encodeURIComponent(flowDescription)}`,
        );
      },
      key: 'copy',
    },
  ];

  return (
    <ActionColumnCell
      key={'contact-action-column-cell'}
      loading={deleteLoadingV2}
      options={options.reverse()}
      cell={cell}
    />
  );
};

export default FlowActionColumnCell;
