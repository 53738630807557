import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useRemark } from 'react-remark';
import remarkHtml from 'remark-html';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
} from '~/components/atom/Typography';
import BlockQuote from '~/components/atom/BlockQuote';

export type Props = {
  value: string;

  options?: {
    remarkReactComponents: any;
  };
};

const Markdown: React.FCC<Props> = ({
  value,
  dataTestId,
  options,
  ...rest
}) => {
  const _options = options
    ? {
        remarkReactComponents: {
          ...defaultRemarkReactComponents,
          ...options.remarkReactComponents,
        },
      }
    : {
        remarkReactComponents: defaultRemarkReactComponents,
      };

  const [content, setMarkdownSource] = useRemark({
    remarkToRehypeOptions: { allowDangerousHtml: true },

    // The typing of these plugins don't quite match the type of the useRemark.
    // @ts-ignore
    remarkPlugins: [remarkHtml, remarkBreaks],
    // @ts-ignore
    rehypePlugins: [rehypeRaw],
    rehypeReactOptions: {
      components: {
        ..._options.remarkReactComponents,
      },
    },
  });

  useEffect(() => {
    setMarkdownSource(value);
  }, [value, setMarkdownSource]);

  return (
    <Container data-testid={dataTestId} {...rest}>
      {content}
    </Container>
  );
};

export const defaultRemarkReactComponents = {
  h1: Heading1,
  h2: Heading2,
  h3: Heading3,
  h4: Heading4,
  h5: Heading5,
  h6: Heading6,
  p: ({ children }: React.HTMLAttributes<HTMLParagraphElement>) => (
    <>{children}</>
  ),
  a: (props: React.HTMLAttributes<HTMLAnchorElement>) => (
    <InlineAnchor {...props} target="_blank" rel="noreferrer noopener" />
  ),
  br: () => <br />,
  blockquote: BlockQuote,
};

const Container = styled.div``;

export const InlineAnchor = styled.a<React.HTMLAttributes<HTMLAnchorElement>>(
  ({ theme }) => css`
    color: ${theme.color('text')};
    margin: 0 ${theme.space('xxxs')};
  `,
);

export default Markdown;
