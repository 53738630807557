import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import LinesEllipsis from 'react-lines-ellipsis';
import {
  ContactFiltersFlattenedFragment,
  ContactFilters__Input,
  Exact,
  GetMySegmentsQuery,
  useDeleteSegmentMutation,
  useUpdateSegmentMutation,
} from '~/graphql/types';
import Icon from '~/components/atom/Icon';
import TEST_ID from './index.testid';
import { useRecoilState } from 'recoil';
import { animated } from 'react-spring';
import useConfirmModal from '~/hooks/useConfirmModal';
import type { OptionOf } from '~/components/molecule/Dropdown';
import { LabelContainer } from '../../../../commonComponents';
import {
  cleanInflatedContactFilters,
  inflateContactFilters,
} from '~/components/organism/Filters/util/contactFilter/filterTranslationHelpers';
import formatToastMessage from '~/util/formatToastMessage';
import serializeFiltersV2 from '~/components/page/Contacts/util/serializeFiltersV2';
import copyTextToClipboard from '~/util/copyTextToClipboard';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useAddToast from '~/hooks/useAddToast';
import { OperationVariables, WatchQueryOptions } from '@apollo/client';
import activeFilter from '../../state/activeFilter';
import { LevelType } from '~/components/molecule/Toast';
import DropdownListContainer from '~/components/molecule/Dropdown/components/DropdownListContainer';
import InputWithSaveButton from '~/components/molecule/InputWithSaveButton';

export type Props = {
  /** Id of the segment */
  id: string;

  /** Name of the segment */
  name: string;

  /** Current filters in the top filter bar */
  currentFilters: ContactFilters__Input;

  filters: Array<ContactFiltersFlattenedFragment>;

  /** Sets current filters to segment filters */
  onFilterChangeV2: (newFilters: ContactFilters__Input) => void;

  /** Updates the result of getMySegments query */
  updateQuery: <
    TVars extends OperationVariables = Exact<{ accountId: string }>,
  >(
    mapFn: (
      previousQueryResult: GetMySegmentsQuery,
      options: Pick<WatchQueryOptions<TVars, GetMySegmentsQuery>, 'variables'>,
    ) => GetMySegmentsQuery,
  ) => void;
};

const text = {
  confirmTitle: 'Weet je het zeker?',
  confirmMessage:
    'Verwijderen van een filter kan niet ongedaan worden gemaakt.',
  confirmDeleteButton: 'Ok',
  updateErrorMessage: 'Er is iets misgegaan bij het updaten van het filter',
  renameErrorMessage: 'Er is iets misgegaan bij het hernoemen van het filter',
  deleteErrorMessage: 'Er is iets misgegaan bij het verwijderen van het filter',
  copyErrorMessage:
    'Er is iets misgegaan bij het kopiëren van de link naar het filter',
  updateSuccessMessage: 'Wijzigingen opgeslagen',
  deleteSuccessMessage: 'Filter is succesvol verwijderd',
  copySuccessMessage: 'Link is gekopieerd',
};

const SegmentRow: React.FCC<Props> = ({
  id,
  name: initialName,
  updateQuery,
  filters,
  currentFilters,
  onFilterChangeV2,
  ...rest
}) => {
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();
  const showToastMessage = useCallback((message: string, type: LevelType) => {
    addToast([formatToastMessage(message, type)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [disabledSegmentId, setDisabledSegmentId] = useState<string | null>(
    null,
  );

  const [updateSegment, { loading: updateLoading }] =
    useUpdateSegmentMutation();
  const [deleteSegment, { loading: deleteLoading }] =
    useDeleteSegmentMutation();

  const [{ segmentId: activeSegmentId }, setActiveFilter] =
    useRecoilState(activeFilter);

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [name, setName] = useState<string>(initialName);

  const disabled = (deleteLoading || updateLoading) && disabledSegmentId === id;

  const [showDeleteConfirmModal] = useConfirmModal({
    labels: {
      title: text.confirmTitle,
      message: text.confirmMessage,
      buttonConfirmTitle: text.confirmDeleteButton,
    },
    onConfirmDefault: () => onDelete(),
    actionType: 'destructive',
  });

  const inflatedFilters = cleanInflatedContactFilters(
    inflateContactFilters({
      contactFilters: filters,
    }),
  ) as ContactFilters__Input;

  const onDelete = useCallback(() => {
    setDisabledSegmentId(id);

    void deleteSegment({
      variables: {
        accountId,
        segmentId: id,
      },
    }).then(({ data, errors }) => {
      setDisabledSegmentId(null);

      if (errors && errors.length > 0) {
        return showToastMessage(text.deleteErrorMessage, 'danger');
      }

      if (data) {
        updateQuery(prev => ({
          ...prev,
          getMySegments: prev.getMySegments.filter(
            segment => segment.id !== id,
          ),
        }));

        return showToastMessage(text.deleteSuccessMessage, 'success');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, deleteSegment, id, updateQuery]);

  const handleUpdate = useCallback(
    ({
      filters,
      name,
      type,
    }: {
      filters: ContactFilters__Input;
      name: string;
      type: 'update' | 'rename';
    }) => {
      void updateSegment({
        variables: {
          accountId,
          segmentId: id,
          filters,
          name,
        },
      }).then(({ data, errors }) => {
        if (errors && errors.length > 0) {
          if (type === 'rename') setName(initialName);
          return showToastMessage(text.updateErrorMessage, 'danger');
        }

        if (data) {
          updateQuery(prev => ({
            ...prev,
            getMySegments: prev.getMySegments.map(segment => {
              if (segment.id === id) return { ...segment, name };
              return segment;
            }),
          }));

          if (type === 'update') {
            setActiveFilter({
              filterId: null,
              segmentId: id,
            });
          }

          setEditing(false);
          return showToastMessage(text.updateSuccessMessage, 'success');
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, id, initialName, updateQuery, updateSegment],
  );

  const onCopyLink = useCallback(() => {
    const segmentUrl = `${
      process.env.BASE_URL
    }/-/contacts/page/1?f=${serializeFiltersV2(inflatedFilters)}`;

    void copyTextToClipboard(segmentUrl)
      .then(() => showToastMessage(text.copySuccessMessage, 'success'))
      .catch(() => showToastMessage(text.copyErrorMessage, 'danger'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inflatedFilters]);

  const onChange = useCallback(
    ({ option }) => {
      switch (option.payload) {
        case 'delete':
          showDeleteConfirmModal();
          return setShowDropdown(false);

        case 'update':
          handleUpdate({ filters: currentFilters, name, type: 'update' });
          return setShowDropdown(false);

        case 'copy-link':
          onCopyLink();
          return setShowDropdown(false);

        case 'rename':
          setEditing(true);
          return setShowDropdown(false);

        default:
          return setShowDropdown(false);
      }
    },
    [onCopyLink, showDeleteConfirmModal, currentFilters, name, handleUpdate],
  );

  const onClick = useCallback(() => {
    onFilterChangeV2(inflatedFilters);
    setActiveFilter({
      segmentId: id,
      filterId: null,
    });
  }, [id, inflatedFilters, onFilterChangeV2, setActiveFilter]);

  const [tooltipEnabled, setTooltipEnabled] = useState<boolean>(false);

  if (editing) {
    return (
      <InputWithSaveButton
        name="rename-segment-input"
        value={name}
        initialValue={initialName}
        onChange={e => setName(e.target.value)}
        onCancel={() => {
          setName(initialName);
          setEditing(false);
        }}
        onSave={() =>
          handleUpdate({ filters: inflatedFilters, name, type: 'rename' })
        }
        onClose={() => setEditing(false)}
        disabled={updateLoading}
        autoFocus
      />
    );
  }

  const hasFocus = showDropdown || activeSegmentId === id;

  return (
    <ListItem
      data-testid={TEST_ID.CONTAINER}
      $hasFocus={hasFocus}
      $disabled={disabled}
      {...rest}
    >
      <LabelContainer
        onClick={onClick}
        data-objectid={name}
        data-active={hasFocus}
        title={tooltipEnabled ? name : undefined}
      >
        <LinesEllipsis
          text={name}
          maxLine={1}
          ellipsis="..."
          trimRight
          basedOn="letters"
          onReflow={({ clamped }) => setTooltipEnabled(clamped)}
        />
      </LabelContainer>

      {!editing && (
        <StyledButton
          $hasFocus={hasFocus}
          onClick={() => {
            if (!disabled) setShowDropdown(!showDropdown);
          }}
          $disabled={disabled}
          data-testid={TEST_ID.DOT_MENU}
        >
          <Icon name="dot-menu" />
        </StyledButton>
      )}

      <StyledDropdownListContainer
        className="positionedList"
        dataTestId={TEST_ID.DROPDOWN}
        dropdownListOpen={showDropdown}
        onClickOutside={() => setShowDropdown(false)}
        options={dropdownOptions}
        onChange={onChange}
        onClose={() => setShowDropdown(false)}
      />
    </ListItem>
  );
};

const dropdownOptions: Array<
  OptionOf<'update' | 'copy-link' | 'rename' | 'delete'>
> = [
  { key: 'update', payload: 'update', label: 'Update deze filter' },
  { key: 'copy-link', payload: 'copy-link', label: 'Kopieer link naar filter' },
  { key: 'rename', payload: 'rename', label: 'Hernoemen' },
  { key: 'delete', payload: 'delete', label: 'Verwijderen' },
];

export const ListItem = styled(animated.li)<{
  $hasFocus?: boolean;
  $disabled?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  ${({ theme, $hasFocus }) => css`
    margin: 0 ${theme.space('xxxs')} ${theme.space('xxxs')} 0;
    margin-left: -${theme.space('xxs')};
    width: calc(${theme.space('xxs')} * 2 + 100%);
    border-radius: ${theme.getTokens().border.radius.base};
    background-color: ${$hasFocus ? theme.color('primary') : 'transparent'};
    color: ${$hasFocus ? theme.color('white') : 'auto'};
    transition: all 0.3s ease-out;
  `};

  ${({ $disabled, $hasFocus, theme }) => {
    if ($disabled) {
      return css`
        cursor: not-allowed;
        background-color: ${theme.color('grey')};
      `;
    }

    return css`
      &:hover {
        background-color: ${$hasFocus
          ? theme.color('primary')
          : theme.color('primary', 'light')};
        color: ${theme.color('white')};
      }
    `;
  }}
`;

const StyledDropdownListContainer = styled(DropdownListContainer)<{}>(
  ({ theme }) => css`
    top: ${theme.space('xxl')};
    right: 0;
  `,
);

const StyledButton = styled.div<{ $hasFocus: boolean; $disabled: boolean }>(
  ({ theme, $hasFocus, $disabled }) => css`
    border-radius: 0 ${theme.getTokens().border.radius.base}
      ${theme.getTokens().border.radius.base} 0;
    background-color: ${$hasFocus
      ? theme.color('primary')
      : $disabled
        ? theme.color('grey')
        : 'transparent'};
    cursor: ${$disabled ? 'not-allowed' : 'pointer'};
    border: none;
    width: ${theme.space('xxl')};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-out;

    &:hover {
      background-color: ${!$disabled
        ? theme.color('primary')
        : theme.color('grey')};
    }
  `,
);

export default SegmentRow;
