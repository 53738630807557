import React from 'react';
import styled, { css } from 'styled-components';
import { Size, componentSizes } from '~/styles/constants';
import { isEmpty, isNil } from 'ramda';
import { Label, Props as TypographyProps } from '../Typography';
import TEST_ID from './index.testid';
import withRequiredMark from '~/hocs/withRequiredMark';

export type Props = {
  /** Label to be displayed if it is passed */
  label?: string | React.ReactNode;

  /** Error message that replaces the label if it is passed */
  error?: string | null;

  /** Size of the label */
  size?: Size;

  required?: boolean;

  margin?: TypographyProps['margin'];
};

/**
 * Label used in inputs and dropdowns
 */
const InputLabel: React.FCC<Props> = ({
  error,
  label,
  size = 'medium',
  required = false,
  margin,
}) => {
  const hasError = !isNil(error) && !isEmpty(error);

  const Comp = required === true ? WithRequiredMark : StyledLabel;

  if (hasError)
    return (
      <Comp
        color={{ group: 'danger' }}
        $size={size}
        $hasError={hasError}
        margin={margin || [null, null, 'xxxs', null]}
        data-testid={TEST_ID.ERROR_LABEL}
      >
        {error}
      </Comp>
    );

  if (label)
    return (
      <Comp
        color={{ group: 'text' }}
        $size={size}
        margin={margin || [null, null, 'xxxs', null]}
        data-testid={TEST_ID.LABEL}
      >
        {label}
      </Comp>
    );

  return null;
};

type LabelProps = TypographyProps & {
  $size: Size;
  $hasError?: boolean;
};

const WithRequiredMark = withRequiredMark<LabelProps>(props => (
  <StyledLabel {...props} />
));

const StyledLabel = styled(Label)<LabelProps>(
  ({ $size = 'medium', theme }) => css`
    font-size: ${theme.fontSize(componentSizes[$size].fontSize)};
  `,
);

export default InputLabel;
