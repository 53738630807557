import React, { type RefObject } from 'react';
import styled, { useTheme } from 'styled-components';
import SectionContainer from '../SectionContainer';
import EditableText from '~/components/organism/EditableText';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Button from '~/components/atom/Button';
import type { SetterOrUpdater } from 'recoil';
import type { FormBuilder_ScreenNode } from '~/graphql/types';
import { Body } from '~/components/atom/Typography';
import Icon from '~/components/atom/Icon';

const text = {
  cancel: 'Afbreken',
  save: 'Opslaan',
  unresolvedIssues: 'Er zijn nog onopgeloste problemen',
};

export type Props = {
  node: FormBuilder_ScreenNode;
  setNodeState: SetterOrUpdater<FormBuilder_ScreenNode | null>;
  initialState: RefObject<FormBuilder_ScreenNode>;
  hasIssues?: boolean;
  onCancel: () => void;
  onSave: (update: FormBuilder_ScreenNode) => void;
};

const TopBar: React.FCC<Props> = ({
  node,
  setNodeState,
  onSave,
  onCancel,
  initialState,
  hasIssues,
}) => {
  const theme = useTheme();
  return (
    <SectionContainer gap="m" margin={[null, null, 'l', null]}>
      <EditableTextContainer>
        <EditableText
          as="h1"
          headerSize="l"
          iconSize="m"
          className="typography"
          text={node?.name}
          onSave={name =>
            setNodeState(prev => (!prev ? prev : { ...prev, name }))
          }
          truncate
        />
      </EditableTextContainer>
      {hasIssues && (
        <JustificationContainer width="100%" justification="end" gap="xxs">
          <Icon name="error" color={theme.color('danger')} />
          <Body size="base" color={{ group: 'danger' }} margin={[null]}>
            {text.unresolvedIssues}
          </Body>
        </JustificationContainer>
      )}
      <JustificationContainer gap="m">
        <Button
          label={text.cancel}
          appearance="danger"
          ghost
          onClick={() => {
            setNodeState(prev => (!prev ? prev : initialState.current));
            onCancel();
          }}
        />
        <Button
          label={text.save}
          icon="save"
          appearance="secondary"
          onClick={() => {
            if (node) onSave(node);
          }}
        />
      </JustificationContainer>
    </SectionContainer>
  );
};

const EditableTextContainer = styled.div`
  width: 70%;
`;

export default TopBar;
