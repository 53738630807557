import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import Icon, { IconType } from '~/components/atom/Icon';
import useTooltipLayer from '~/hooks/useTooltipLayer';

export type Props = {
  icon: IconType;
  active?: boolean;
  tooltipMessage?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children?: React.ReactNode;
};

const BaseButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      dataTestId,
      active,
      icon,
      tooltipMessage,
      children,
      disabled = false,
      onClick,
      ...rest
    },
    ref,
  ) => {
    const tooltipProps = useTooltipLayer({ tooltipMessage });

    return (
      <ToolbarButton
        ref={ref}
        data-testid={dataTestId}
        $active={active}
        $disabled={disabled}
        onClick={e => {
          if (disabled) return;
          onClick(e);
          tooltipProps.onMouseLeave(e);
        }}
        {...tooltipProps}
        {...rest}
      >
        <Icon name={icon} strokeWidth={2.5} />
        {children}
      </ToolbarButton>
    );
  },
);

export const ToolbarButton = styled.button<{
  $active?: boolean;
  $disabled: boolean;
}>`
  ${({ $active, theme, $disabled }) => css`
    display: flex;
    align-items: center;
    border: ${theme.getTokens().border.width.s} solid
      ${$active ? theme.color('white', 'dark') : 'transparent'};
    cursor: ${$disabled ? 'not-allowed' : 'pointer'};
    padding: ${theme.space('xxs')};
    margin: ${theme.space('xxxs')};

    border-radius: ${theme.getTokens().border.radius.s};
    font-size: ${theme.fontSize('base')};

    background-color: ${$active ? theme.color('primary') : 'transparent'};
    color: ${$active
      ? theme.color('white')
      : $disabled
        ? theme.color('grey')
        : theme.color('primary', 'light')};

    &:hover {
      color: ${!$active && !$disabled && theme.color('primary', 'light')};
      background-color: ${!$active &&
      !$disabled &&
      theme.color('white', 'dark')};
    }

    transition: all 0.3s ease-out;
  `};
`;

export default BaseButton;
