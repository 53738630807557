import type { IconType } from '~/components/atom/Icon';
import type {
  FormBuilder_Locale,
  FormBuilder_ScreenNode_Block,
} from '~/graphql/types';
import type { OptionOf } from '~/components/molecule/Dropdown';
import * as TypographyComps from '~/components/atom/Typography';
import { Props as TypographyProps } from '~/components/atom/Typography';
import type { TypographyComponentType } from '../components/ScreenPreview/utils/formatNode/types';

export const ItemTypes = {
  BLOCK_COMPONENT: 'BLOCK_COMPONENT',
};
export const SUBMIT_SCREEN_TITLE = 'Verzendpagina';

export const AREA_HEIGHT = '650px';
export enum DND_SOURCES {
  componentsCollection = 'componentsCollection',
  blocksArea = 'blocksArea',
}

export const localeToIntlKey: Record<FormBuilder_Locale, string> = {
  NL: 'nl',
  EN: 'en',
};

export const localeToLabel: Record<FormBuilder_Locale, string> = {
  NL: 'Label (🇳🇱)',
  EN: 'Label (🇬🇧)',
};

export type ScreenNodeBlock_FE =
  | FormBuilder_ScreenNode_Block['__typename']
  | 'FormData_GDPR';

export type BlocksMap = {
  [key in ScreenNodeBlock_FE]: {
    icon: IconType;
    label: string;
    color: string;
    placeHolder?: string;
  };
};

export const blocksMap: BlocksMap = {
  FormData_Input_Email: {
    label: 'E-mailadres',
    icon: 'email-sign',
    color: '#1E2647',
    placeHolder: 'example@email.com',
  },
  FormData_Input_Integer: {
    label: 'Getal',
    icon: 'number',
    color: '#FF6900',
    placeHolder: '13567894342',
  },
  FormData_Input_Multiline: {
    label: 'Meerdere regels tekst',
    icon: 'multi-choice',
    color: '#3763D4',
    placeHolder:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  },
  FormData_Input_Text: {
    label: 'Enkele regel tekst',
    icon: 'text-background',
    color: '#FFB464',
    placeHolder: 'Placeholder text',
  },
  FormData_Select_Dropdown: {
    label: 'Keuzemenu',
    icon: 'dropdown',
    color: '#018827',
  },
  FormData_Select_MultiButton: {
    label: 'Multiple-choice',
    icon: 'multi-choice',
    color: '#FFD6A8',
  },
  FormData_Select_Radio: {
    label: 'Enkele keuzeknop',
    icon: 'single-choice',
    color: '#C6020B',
  },
  FormData_UI_Image: {
    label: 'Afbeelding',
    icon: 'image',
    color: '#B6D3FF',
  },
  FormData_UI_RichText: {
    label: 'Opgemaakte paragraaf',
    icon: 'rich-text',
    color: '#213C80',
  },
  FormData_UI_Typography: {
    label: 'Koptekst',
    icon: 'typography',
    color: '#642901',
  },
  FormData_Input_Contact_Email: {
    icon: 'mail',
    label: 'E-mailadres',
    color: '#FFB464',
    placeHolder: 'example@email.com',
  },
  FormData_Input_Contact_Name: {
    icon: 'typography',
    label: 'Naam',
    color: '#A2040E',
    placeHolder: 'John Johnson',
  },
  FormData_Input_Contact_Phone: {
    icon: 'phone',
    // The `-` in `telefoon-nummer` is added to break `nummer` word to the next line
    label: 'Telefoon- nummer',
    color: '#018827',
    placeHolder: '3197010587401',
  },
  FormData_GDPR: {
    icon: 'check-square',
    label: 'AVG',
    color: '#213C80',
  },
};

export const BLOCK_OPTIONS: Array<OptionOf<ScreenNodeBlock_FE>> = [
  {
    payload: 'FormData_Input_Contact_Name',
    key: 'FormData_Input_Contact_Name',
    label: blocksMap['FormData_Input_Contact_Name'].label,
  },
  {
    payload: 'FormData_Input_Contact_Email',
    key: 'FormData_Input_Contact_Email',
    label: blocksMap['FormData_Input_Contact_Email'].label,
  },
  {
    payload: 'FormData_Input_Contact_Phone',
    key: 'FormData_Input_Contact_Phone',
    label: blocksMap['FormData_Input_Contact_Phone'].label,
  },
  {
    payload: 'FormData_Input_Text',
    key: 'FormData_Input_Text',
    label: blocksMap['FormData_Input_Text'].label,
  },
  {
    payload: 'FormData_Input_Multiline',
    key: 'FormData_Input_Multiline',
    label: blocksMap['FormData_Input_Multiline'].label,
  },
  {
    payload: 'FormData_Input_Integer',
    key: 'FormData_Input_Integer',
    label: blocksMap['FormData_Input_Integer'].label,
  },
  {
    payload: 'FormData_Select_Dropdown',
    key: 'FormData_Select_Dropdown',
    label: blocksMap['FormData_Select_Dropdown'].label,
  },
  {
    payload: 'FormData_Select_Radio',
    key: 'FormData_Select_Radio',
    label: blocksMap['FormData_Select_Radio'].label,
  },
  {
    payload: 'FormData_UI_RichText',
    key: 'FormData_UI_RichText',
    label: blocksMap['FormData_UI_RichText'].label,
  },
  {
    payload: 'FormData_UI_Image',
    key: 'FormData_UI_Image',
    label: blocksMap['FormData_UI_Image'].label,
  },
  {
    payload: 'FormData_GDPR',
    key: 'FormData_Select_Radio',
    label: blocksMap['FormData_GDPR'].label,
  },
];

export const typographyMap: Record<
  TypographyComponentType['props']['type'],
  React.FCC<TypographyProps>
> = {
  Heading1: TypographyComps.Heading1,
  Heading2: TypographyComps.Heading2,
  Heading3: TypographyComps.Heading3,
  Heading4: TypographyComps.Heading4,
  Heading5: TypographyComps.Heading5,
  Heading6: TypographyComps.Heading6,
  SubHeading: TypographyComps.SubHeading,
  Body: TypographyComps.Body,
};
