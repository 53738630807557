import React from 'react';
import { v4 as uuid } from 'uuid';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import Button from '~/components/atom/Button';
import type {
  FormBuilder_Label_Intl,
  FormBuilder_OptionList,
  FormBuilder_OptionList_Option,
} from '~/graphql/types';
import Input from '~/components/molecule/Input';
import { localeToIntlKey, localeToLabel } from '../../../../constants';
import { isNil, lensIndex, over } from 'ramda';
import { optionListById } from '~/components/page/Forms/components/Builder/state/optionList';
import { formState as formStateAtom } from '~/components/page/Forms/components/Builder/state';
import { issuesByPath } from '~/components/page/Forms/components/Builder/state/issues';
import JustificationContainer from '~/components/atom/JustificationContainer';

type Props = {
  listId: FormBuilder_OptionList['id'];
  blockKey: string;
  nodeId: string;
};

const OptionsEditor: React.FCC<Props> = ({ listId, nodeId, blockKey }) => {
  const formState = useRecoilValue(formStateAtom);
  const [optionList, setOptionList] = useRecoilState(optionListById(listId));
  const issues = useRecoilValue(issuesByPath([nodeId, blockKey]));

  const issueMap = issues.reduce(
    (acc, curr) => {
      if (curr && curr.optionKey && curr.localeKey && !isNil(curr.message)) {
        acc[curr.optionKey] = acc[curr.optionKey] ?? {};
        acc[curr.optionKey][curr.localeKey] = curr.message;
      }

      return acc;
    },
    {} as Record<string, Record<string, string>>,
  );

  return (
    <JustificationContainer width="100%" gap="m" direction="column">
      <ListContainer>
        {optionList?.options.map(({ key, label, icon }, index) => (
          <ListItem key={key}>
            {formState?.style.availableLocale.map(locale => (
              <Input
                label={localeToLabel[locale]}
                externalErrors={
                  issueMap[key] && issueMap[key][locale]
                    ? [issueMap[key][locale]]
                    : []
                }
                key={locale}
                width="100%"
                size="small"
                onChange={event => {
                  const lens = lensIndex<FormBuilder_OptionList_Option>(index);

                  setOptionList(prev => {
                    if (!prev) return prev;
                    const updatedOption: FormBuilder_OptionList_Option = {
                      key,
                      label: {
                        ...label,
                        [localeToIntlKey[locale]]: event.target.value,
                      },
                      icon,
                      __typename: 'FormBuilder_OptionList_Option',
                    };

                    const updatedOptions = over(
                      lens,
                      () => updatedOption,
                      prev.options,
                    );

                    return { ...prev, options: updatedOptions };
                  });
                }}
                value={label[localeToIntlKey[locale]]}
              />
            ))}
            <div>
              <Button
                icon="delete"
                onClick={() => {
                  setOptionList(prev => {
                    if (!prev) return prev;

                    return {
                      ...prev,
                      options: prev.options.filter(
                        option => option.key !== key,
                      ),
                    };
                  });
                }}
                appearance="danger"
                size="small"
              />
            </div>
          </ListItem>
        ))}
      </ListContainer>
      <Button
        size="small"
        label="Nieuwe optie toevoegen"
        icon="plus"
        onClick={() => {
          setOptionList(prev => {
            if (!prev) return prev;
            return {
              ...prev,
              options: [
                ...prev.options,
                createNewOption({
                  __typename: 'FormBuilder_Label_Intl',
                  en: `Option ${prev.options.length + 1}`,
                  nl: `Optie ${prev.options.length + 1}`,
                }),
              ],
            };
          });
        }}
      />
    </JustificationContainer>
  );
};

export const createNewOption = (
  label: FormBuilder_Label_Intl,
): FormBuilder_OptionList_Option => ({
  __typename: 'FormBuilder_OptionList_Option',
  key: uuid(),
  label,
});

const ListContainer = styled.ul(
  ({ theme }) => css`
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${theme.space('m')};
  `,
);

const ListItem = styled.li(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    gap: ${theme.space('m')};
    width: 100%;
    align-items: flex-end;
  `,
);

export default OptionsEditor;
