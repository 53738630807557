/**
 *
 * A Hook to help use the Confirm modal with a specific payload. It will give the user:
 *  [0] - the component to render. Always show it, if it should be visible is handled by the hook
 *  [1] - the function to show the modal
 *  [2] - a function to update what happens on confirm
 *
 * It expects:
 *  labels - object that determines the label of the modal
 *  onConfirm? - what to do if the user clicks the confirm button
 *  onCancel? - what to do if the user clicks the cancel button
 *  dataTestId? - test id to show
 */

import React, { useContext, useRef } from 'react';
import ConfirmModal, {
  Props as ConfirmModalProps,
} from '~/components/template/ConfirmModal';
import ModalContext from '~/contexts/ModalContext';

export type ConfirmFunction = () => any;
export type CancelFunction = () => any;
type ReturnProps = [() => void, (confirmFn: ConfirmFunction) => void];
export type ModalLabels = {
  title: string;
  message: string;
  buttonConfirmTitle?: string;
  buttonCancelTitle?: string;
};
type Args = {
  labels: ModalLabels;
  onConfirmDefault?: ConfirmFunction;
  onCancel?: CancelFunction;
  dataTestId?: string;
  hideCancel?: boolean;
  actionType?: ConfirmModalProps['actionType'];
};

const useConfirmModal = ({
  labels,
  onConfirmDefault,
  onCancel,
  dataTestId,
  hideCancel = false,
  actionType,
}: Args): ReturnProps => {
  const { showModal, hideModal } = useContext(ModalContext);
  const onConfirm = useRef<ConfirmFunction>(
    onConfirmDefault == null ? () => {} : onConfirmDefault,
  );
  const { title, message, buttonCancelTitle, buttonConfirmTitle } = labels;

  return [
    () =>
      showModal(
        <ConfirmModal
          title={title}
          message={message}
          buttonCancelTitle={buttonCancelTitle}
          buttonConfirmTitle={buttonConfirmTitle}
          hideCancel={hideCancel}
          dataTestId={dataTestId}
          actionType={actionType}
          handleAction={confirm => {
            if (confirm === true) {
              if (onConfirm.current != null) onConfirm.current();
            } else {
              if (onCancel != null) onCancel();
            }

            hideModal();
          }}
        />,
      ),
    newFn => {
      onConfirm.current = newFn;
    },
  ];
};

export default useConfirmModal;
