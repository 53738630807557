import React, { useEffect, useState } from 'react';

import {
  BillingCycle,
  GetBillingDetailsQuery,
  GetPlansQuery,
  PlanOptionFragment,
  useGetBillingDetailsQuery,
  useGetPlansQuery,
  useSignupMutation,
  useUpdatePlanMutation,
} from '~/graphql/types';

import Modal from '~/components/organism/ModalsV2/Modal';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import ChoosePlanStep from './steps/ChoosePlanStep';
import PlanOverviewStep from './steps/PlanOverviewStep';
import SetupPayment from './steps/SetupPayment';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Catalog from '~/Catalog';
import useCurrentUser from '~/hooks/useCurrentUser';
import useSignupParams from '~/hooks/useSignupParams';
import Loading from '~/components/atom/Loading';
import { Coupon } from '~/components/page/Settings/Subscription/utils/convertToCoupon';
import { isNil } from 'ramda';
import { animated, useTransition } from 'react-spring';
import getTapfillateRef from './utils/getTapfillateRef';
import CancelSubscriptionStep from './steps/CancelSubscriptionStep';
import { ApolloError } from '@apollo/client';
import useFireTrackingEvent from '~/hooks/useFireTrackingEvent';
import getStoredUTMParams from '~/util/getStoredUTMParams';
import getSignupId from './utils/getSignupId';

const text = {
  insertionErrorMsg: Catalog.genericUnknownErrorMessageShort,
  header: 'Kies je abonnement',
};
type Props = {
  onComplete: () => void;
  onClose?: () => void;
};

type StepId =
  | 'choosePlan'
  | 'planOverview'
  | 'setupPayment'
  | 'cancelSubscription';
export const SUBSCRIPTION_MODAL_WIDTH = '890px';

const ChangeOrUpdateSubscription: React.FC<Props> = ({
  onComplete,
  onClose,
}) => {
  const fireTrackingEvent = useFireTrackingEvent();
  const account = useCurrentAccount();
  const me = useCurrentUser();

  const {
    data: billingDetailsData,
    loading: loadingBillingDetails,
    refetch: refetchBillingDetails,
  } = useGetBillingDetailsQuery({
    variables: {
      accountId: account.id,
    },
  });

  const {
    data,
    loading: loadingPlans,
    refetch: refetchPlans,
  } = useGetPlansQuery({
    variables: {
      accountId: account.id,
      signupId: getSignupId(),
    },
  });
  const refetchAll = () => Promise.all([refetchPlans, refetchBillingDetails]);

  const { couponCode } = useSignupParams();
  const [signupMutation, { error: signupError, loading: loadingSignup }] =
    useSignupMutation();
  const [updatePlanMutation, { error: updateError, loading: loadingUpdate }] =
    useUpdatePlanMutation();

  const loading = loadingSignup || loadingUpdate;
  const error = signupError || updateError;

  const [updateData, setUpdateData] = useState<{
    plan: null | PlanOptionFragment;
    billingCycle: BillingCycle;
    coupon: null | Coupon;
  }>({
    plan: null,
    billingCycle: BillingCycle.Yearly,
    /* Initial coupon will be set later by PlanOverviewStep/CouponInput component if couponCode is a valid coupon.
     * We are doing this because
     *   1 - The data is flowing both ways in the existing design. (Which is not ideal)
     *   2 - Separate the coupon logic and contain it in CouponInput
     * We will revisit this when we work on the setup wizard.
     */
    coupon: null,
  });

  useEffect(() => {
    if (updateData.plan === null) {
      const currentPlanOption = data?.getPlans.available.find(
        ({ id }) => id === data.getPlans.current?.id,
      );

      const currentCycle =
        data?.getPlans.current?.billingCycle ?? BillingCycle.Yearly;

      setUpdateData(prev => ({
        ...prev,
        billingCycle: currentCycle,
        plan: currentPlanOption ?? null,
      }));
    }
  }, [data, updateData.plan]);

  const [billingInfo, setBillingInfo] = useState<{
    name: string | null;
    email: string | null;
  }>({
    name: me.name,
    email: me.email,
  });

  const [currentStep, setCurrentStep] = useState<StepId>('choosePlan');
  const transitions = useTransition(currentStep, {
    from: { opacity: 0, transform: 'translate3d(-40px,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0px,0,0)' },
    leave: {
      opacity: 0,
      transform: 'translate3d(-40px,0,0)',
      position: 'absolute',
    },
  });

  const onInsert = async ({
    planId,
    paymentId,
    billingCycle,
    coupon,
    shouldUseUpdate = false,
  }: {
    planId: string;
    paymentId?: string;
    billingCycle: BillingCycle;
    coupon: null | Coupon;
    shouldUseUpdate?: boolean;
  }) => {
    const mutation = shouldUseUpdate ? updatePlanMutation : signupMutation;
    const referralCode = getTapfillateRef();

    return mutation({
      variables: {
        accountId: account.id,
        paymentId,
        planId,
        billingCycle,
        coupon: coupon?.id ?? undefined,
        referralCode: shouldUseUpdate ? undefined : referralCode,
        signupId: getSignupId(),
      },
    }).then(result => {
      if (!isNil(result.data)) {
        void refetchAll();

        // First time selecting a plan means signup
        // this is when we fire our conversion event
        if (!shouldUseUpdate) {
          const utm = getStoredUTMParams();

          fireTrackingEvent({
            event: 'completeRegistration',
            planId,
            ...utm,
          });
        }

        // if successful
        onComplete();
      }
    });
  };

  return (
    <Overlay onClose={onClose}>
      <Modal maxWidth={SUBSCRIPTION_MODAL_WIDTH} onClose={onComplete}>
        {transitions(
          (style, item, { key }) =>
            item === currentStep && (
              <animated.div key={key} style={style}>
                <StepComponent
                  billingDetailsData={billingDetailsData}
                  billingInfo={billingInfo}
                  couponCode={couponCode}
                  currentStep={currentStep}
                  data={data}
                  loading={loadingPlans || loadingBillingDetails}
                  onClose={onClose}
                  onComplete={onComplete}
                  onInsert={onInsert}
                  setBillingInfo={setBillingInfo}
                  setCurrentStep={setCurrentStep}
                  setUpdateData={setUpdateData}
                  loadingMutation={loading}
                  mutationError={error}
                  updateData={updateData}
                />
              </animated.div>
            ),
        )}
      </Modal>
    </Overlay>
  );
};

const StepComponent: React.FC<{
  loading: boolean;
  currentStep: StepId;
  data?: GetPlansQuery;
  billingDetailsData?: GetBillingDetailsQuery;
  setUpdateData: React.Dispatch<
    React.SetStateAction<{
      plan: null | PlanOptionFragment;
      billingCycle: BillingCycle;
      coupon: null | Coupon;
    }>
  >;
  setBillingInfo: React.Dispatch<
    React.SetStateAction<{
      name: string | null;
      email: string | null;
    }>
  >;
  setCurrentStep: React.Dispatch<React.SetStateAction<StepId>>;
  onInsert: ({
    planId,
    paymentId,
    billingCycle,
    coupon,
    shouldUseUpdate,
  }: {
    planId: string;
    paymentId?: string | undefined;
    billingCycle: BillingCycle;
    coupon: null | Coupon;
    shouldUseUpdate?: boolean | undefined;
  }) => Promise<void>;
  onClose: Props['onClose'];
  onComplete: Props['onComplete'];
  couponCode: string | null | undefined;
  billingInfo: {
    name: string | null;
    email: string | null;
  };
  loadingMutation: boolean;
  mutationError: ApolloError | undefined;
  updateData: {
    plan: null | PlanOptionFragment;
    billingCycle: BillingCycle;
    coupon: null | Coupon;
  };
}> = ({
  loading,
  currentStep,
  data,
  setUpdateData,
  updateData,
  billingDetailsData,
  setBillingInfo,
  billingInfo,
  setCurrentStep,
  onInsert,
  onClose,
  onComplete,
  couponCode,
  mutationError,
  loadingMutation,
}) => {
  if (!data || loading) return <Loading />;

  switch (currentStep) {
    case 'choosePlan':
      return (
        <ChoosePlanStep
          header={text.header}
          currentPlan={data.getPlans.current}
          planOptions={data.getPlans.available}
          caveats={data.getPlans.caveats}
          onContinue={(plan, billingCycle) => {
            setUpdateData(prev => ({ ...prev, billingCycle, plan }));
            setCurrentStep('planOverview');
          }}
          onClose={onClose}
          onGoToUnsubscribe={
            !isNil(data.getPlans.current)
              ? () => {
                  setCurrentStep('cancelSubscription');
                }
              : null
          }
        />
      );

    case 'planOverview':
      return (
        <PlanOverviewStep
          selectedPlan={updateData.plan as PlanOptionFragment}
          initialSelectedBillingCycle={updateData.billingCycle}
          initialSelectedCoupon={updateData.coupon}
          initialCouponCode={couponCode}
          onContinue={(billingCycle, coupon) => {
            setUpdateData(prev => ({
              ...prev,
              coupon,
              billingCycle,
            }));

            if (
              updateData.plan?.requiresBilling === true &&
              isNil(billingDetailsData?.getBillingDetails)
            ) {
              return setCurrentStep('setupPayment');
            }

            if (updateData.plan) {
              return onInsert({
                planId: updateData.plan.id,
                billingCycle: updateData.billingCycle,
                coupon: updateData.coupon,
                shouldUseUpdate: !isNil(data.getPlans.current),
              });
            }
          }}
          onGoBack={(billingCycle, coupon) => {
            setUpdateData(prev => ({
              ...prev,
              coupon,
              billingCycle,
            }));

            setCurrentStep('choosePlan');
          }}
          loading={loadingMutation}
        />
      );
    case 'setupPayment': {
      const price = updateData.plan?.price[updateData.billingCycle] ?? 0;
      const totalPrice =
        updateData.billingCycle === BillingCycle.Monthly ? price : price * 12;

      return (
        <SetupPayment
          onContinue={(paymentId: string) => {
            if (updateData.plan) {
              return onInsert({
                planId: updateData.plan.id,
                billingCycle: updateData.billingCycle,
                coupon: updateData.coupon,
                paymentId,
                shouldUseUpdate: !isNil(data.getPlans.current),
              });
            }

            return;
          }}
          onGoBack={billingInfo => {
            setBillingInfo(billingInfo);
            setCurrentStep('planOverview');
          }}
          totalPriceToPay={
            totalPrice -
            (updateData.coupon != null
              ? updateData.coupon.calculateOff(totalPrice)
              : 0)
          }
          insertionLoading={loadingMutation}
          insertionError={mutationError == null ? null : text.insertionErrorMsg}
          initialEmail={billingInfo.email}
          initialName={billingInfo.name}
        />
      );
    }

    case 'cancelSubscription':
      return (
        <CancelSubscriptionStep
          onGoBack={() => setCurrentStep('choosePlan')}
          onSubscriptionCancelled={() => {
            onComplete();
          }}
        />
      );
  }
};

export default ChangeOrUpdateSubscription;
