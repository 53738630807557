import React from 'react';
import {
  FormData_TypographyType,
  type FormBuilder_Locale,
  type FormBuilder_OptionList,
  type FormBuilder_ScreenNode_Block,
} from '~/graphql/types';
import Input from '~/components/molecule/Input';
import {
  blocksMap,
  localeToIntlKey,
  typographyMap,
} from '../../../../constants';
import Dropdown, { type OptionOf } from '~/components/molecule/Dropdown';
import { type IconType } from '~/components/atom/Icon';
import ProgressiveImgIx from '~/components/atom/ProgressiveImgIx';
import Markdown from '~/components/molecule/Markdown';
import { formatOptions } from '../../../ScreenPreview/utils/formatNode';
import SingleChoice from '../../components/SingleChoice';
import LargeText from '../../components/LargeText';
import handleNewLinesInMarkdown from '~/components/molecule/Markdown/utils/handleNewLinesInMarkdown';
import styled, { css } from 'styled-components';

const getBlockComponent = (
  block: FormBuilder_ScreenNode_Block,
  currentLocale: FormBuilder_Locale,
  optionsList: Array<FormBuilder_OptionList>,
) => {
  switch (block.__typename) {
    case 'FormData_Input_Email': {
      return (
        <Input
          label={block.label[localeToIntlKey[currentLocale]]}
          type="email"
          width="100%"
          placeholder={blocksMap[block.__typename].placeHolder}
          required={block.required}
        />
      );
    }

    case 'FormData_Input_Integer': {
      return (
        <Input
          label={block.label[localeToIntlKey[currentLocale]]}
          type="number"
          width="100%"
          placeholder={blocksMap[block.__typename].placeHolder}
          required={block.required}
        />
      );
    }

    case 'FormData_Input_Contact_Email': {
      return (
        <Input
          label={block.label[localeToIntlKey[currentLocale]]}
          type="email"
          width="100%"
          placeholder={blocksMap[block.__typename].placeHolder}
          required={block.required}
        />
      );
    }
    case 'FormData_Input_Contact_Phone': {
      return (
        <Input
          label={block.label[localeToIntlKey[currentLocale]]}
          type="number"
          width="100%"
          placeholder={blocksMap[block.__typename].placeHolder}
          required={block.required}
        />
      );
    }
    case 'FormData_Input_Contact_Name': {
      return (
        <Input
          label={block.label[localeToIntlKey[currentLocale]]}
          type="text"
          width="100%"
          placeholder={blocksMap[block.__typename].placeHolder}
          required={block.required}
        />
      );
    }

    case 'FormData_Input_Text': {
      return (
        <Input
          label={block.label[localeToIntlKey[currentLocale]]}
          type="text"
          width="100%"
          placeholder={blocksMap[block.__typename].placeHolder}
          required={block.required}
        />
      );
    }

    case 'FormData_Select_Dropdown': {
      const options: Array<OptionOf<string>> = formatOptions(
        block.optionListId,
        optionsList,
      ).map(op => ({
        ...op,
        icon: op.icon ? { name: op.icon as IconType } : undefined,
        label: op.label[currentLocale],
        key: op.payload,
        payload: op.payload,
      }));

      return (
        <Dropdown
          options={options}
          label={block.label[localeToIntlKey[currentLocale]]}
          onChange={() => {}}
          required={block.required}
        />
      );
    }

    case 'FormData_UI_Typography': {
      const type = block.type || FormData_TypographyType.Heading1;
      const Comp = typographyMap[type];
      return <Comp>{block.content[localeToIntlKey[currentLocale]]}</Comp>;
    }

    case 'FormData_Input_Multiline': {
      return (
        <LargeText
          label={block.label[localeToIntlKey[currentLocale]]}
          placeholder={blocksMap[block.__typename].placeHolder ?? ''}
          required={block.required}
        />
      );
    }

    case 'FormData_Select_MultiButton': {
      /** Implement this once backend implements this */
      // return (
      //   <MultiChoice
      //     block={block}
      //     optionsList={optionsList}
      //     currentLocale={currentLocale}
      //     required={block.required}
      //   />
      // );
      return null;
    }
    case 'FormData_Select_Radio': {
      return (
        <SingleChoice
          block={block}
          optionsList={optionsList}
          currentLocale={currentLocale}
          required={block.required}
        />
      );
    }

    case 'FormData_UI_Image': {
      return (
        <AlignmentContainer $alignment={block.alignment}>
          <ProgressiveImgIx width={300} src={block.image.url} />
        </AlignmentContainer>
      );
    }

    case 'FormData_UI_RichText': {
      return (
        <AlignmentContainer $alignment={block.alignment}>
          <Markdown
            value={handleNewLinesInMarkdown(
              block.content[localeToIntlKey[currentLocale]],
            )}
          />
        </AlignmentContainer>
      );
    }

    default: {
      return null;
    }
  }
};

const AlignmentContainer = styled.div<{ $alignment: string }>(
  ({ $alignment }) => css`
    display: flex;
    justify-content: ${$alignment};
  `,
);

export default getBlockComponent;
