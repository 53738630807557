import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isNil, isEmpty } from 'ramda';
import AsideHeader from '../AsideHeader';
import { animated, useSpring } from 'react-spring';

import { useRecoilState, useRecoilValue } from 'recoil';
import Textarea from '~/components/bad/Inputs/Textarea';
import { formState } from '../../../../state';
import useMeasure from '~/hooks/useMeasure';
import { issuesBySettingsId } from '../../../../state/issues';
import ImageUploadComponent from '~/components/organism/ImageUploadComponent';
import useImageUpload from '~/hooks/useImageUpload';
import InputLabel from '~/components/atom/InputLabel';
import { FormBuilder_Locale } from '~/graphql/types';

type Props = {
  dataTestId?: string;
  expanded?: boolean;
  onToggleExpand: () => void;
};

const text = {
  heading: 'Widget instellingen',
  title: {
    nl: 'Title 🇳🇱',
    en: 'Title 🇬🇧',
  },
  subTitle: {
    nl: 'Subtitle 🇳🇱',
    en: 'Subtitle 🇬🇧',
  },
  logoImage: 'Logo afbeelding',
};

const WidgetSettings: React.FCC<Props> = ({
  dataTestId,
  expanded = true,
  onToggleExpand,
}) => {
  const [state, setState] = useRecoilState(formState);
  const { ref, bounds } = useMeasure();
  const theme = useTheme();

  const issues = useRecoilValue(issuesBySettingsId('widget-settings'));

  const spring = useSpring({
    height: expanded ? bounds.height + theme.remToPxRaw(theme.space('m')) : 0,
  });

  const availableLocale = state?.style.availableLocale;
  const hasNL = availableLocale?.includes(FormBuilder_Locale.Nl);
  const hasEN = availableLocale?.includes(FormBuilder_Locale.En);

  const onIntlChange = ({
    fieldName,
    key,
    value,
  }: {
    fieldName: 'title' | 'subTitle';
    key: 'nl' | 'en';
    value: string | null;
  }) => {
    setState(prev =>
      prev
        ? {
            ...prev,
            [fieldName]: {
              ...prev[fieldName],
              [key]: value,
            },
          }
        : prev,
    );
  };

  const logoImage = useImageUpload({
    initialUrl: state?.style.theme.logo?.url,
    s3Key: state?.style.theme.logo?.s3key,
    onUpload: ({ s3Key, url }) => {
      setState(prev =>
        prev
          ? {
              ...prev,
              style: {
                ...prev.style,
                theme: {
                  ...prev.style.theme,
                  logo: { __typename: 'DHImage', s3key: s3Key, url },
                },
              },
            }
          : prev,
      );
    },
  });

  const errorLabels = issues.reduce(
    (prev, curr) => {
      if (!curr) return prev;
      if (!curr.inputKey) return prev;

      const [fieldKey, localeKey] = curr.inputKey?.split('-');
      const prevValue = prev[fieldKey] || {};

      return {
        ...prev,
        [fieldKey]: {
          ...prevValue,
          [localeKey]: curr.message || null,
        },
      };
    },
    { title: { nl: null, en: null }, subTitle: { nl: null, en: null } },
  );

  return (
    <Container data-testid={dataTestId}>
      <AsideHeader
        heading={text.heading}
        icon="grid"
        onClick={onToggleExpand}
        expanded={expanded}
      />
      <OverflowContainer style={spring}>
        <Inner ref={ref}>
          <InputContainer>
            <InputLabel label={text.logoImage} />
            <ImageUploadComponent
              uploading={logoImage.uploading}
              imageUrl={logoImage.s3Key != null ? logoImage.url : null}
              onUpload={file =>
                void logoImage.upload({
                  file: file[0],
                  filename: 'default_theme_logo_image',
                })
              }
              direction="column"
            />
          </InputContainer>

          {hasNL && (
            <>
              <InputContainer>
                <Textarea
                  name="title-nl"
                  value={state?.title.nl ?? ''}
                  onChange={value => {
                    if (isNil(value) || isEmpty(value)) {
                      onIntlChange({
                        fieldName: 'title',
                        key: 'nl',
                        value: null,
                      });
                      return;
                    }
                    onIntlChange({ fieldName: 'title', key: 'nl', value });
                  }}
                  error={errorLabels.title.nl}
                  label={text.title.nl}
                />
              </InputContainer>
              <InputContainer>
                <Textarea
                  name="subTitle-nl"
                  value={state?.subTitle.nl ?? ''}
                  onChange={value => {
                    if (isNil(value) || isEmpty(value)) {
                      onIntlChange({
                        fieldName: 'subTitle',
                        key: 'nl',
                        value: null,
                      });
                      return;
                    }
                    onIntlChange({
                      fieldName: 'subTitle',
                      key: 'nl',
                      value,
                    });
                  }}
                  error={errorLabels.subTitle?.nl}
                  label={text.subTitle.nl}
                />
              </InputContainer>
            </>
          )}

          {hasEN && (
            <>
              <InputContainer>
                <Textarea
                  name="title-en"
                  value={state?.title.en ?? ''}
                  onChange={value => {
                    if (isNil(value) || isEmpty(value)) {
                      onIntlChange({
                        fieldName: 'title',
                        key: 'en',
                        value: null,
                      });
                      return;
                    }
                    onIntlChange({ fieldName: 'title', key: 'en', value });
                  }}
                  error={errorLabels.title.en}
                  label={text.title.en}
                />
              </InputContainer>
              <InputContainer>
                <Textarea
                  name="subTitle-en"
                  value={state?.subTitle.en ?? ''}
                  onChange={value => {
                    if (isNil(value) || isEmpty(value)) {
                      onIntlChange({
                        fieldName: 'subTitle',
                        key: 'en',
                        value: null,
                      });
                      return;
                    }
                    onIntlChange({
                      fieldName: 'subTitle',
                      key: 'en',
                      value,
                    });
                  }}
                  error={errorLabels.subTitle.en}
                  label={text.subTitle.en}
                />
              </InputContainer>
            </>
          )}
        </Inner>
      </OverflowContainer>
    </Container>
  );
};

const Container = styled.aside(
  ({ theme }) => css`
    z-index: ${theme.z('top')};
    user-select: none;
    width: 100%;
  `,
);

const Inner = styled.div(
  ({ theme }) => css`
    padding: 0 ${theme.space('m')};
  `,
);

const InputContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.space('xxs')};
  `,
);

const OverflowContainer = styled(animated.div)`
  overflow: hidden;
`;

export default WidgetSettings;
