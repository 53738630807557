import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { EmailSyncStatus, EmailAliasStatus } from '~/graphql/types';
import EmailAlias from './EmailAlias';
import InputGroup from '~/components/bad/Inputs/InputGroup';
import { isSuccessEmailSyncStatus } from '~/util/constants';
import TEST_ID from './SynchronisedMailbox.testid';
import useConfirmModal from '~/hooks/useConfirmModal';
import LinkedSubField from '~/components/bad/Inputs/LinkedSubField';
import HelpButton from '~/components/molecule/HelpButton';
import {
  INPUT_BUTTON_TYPE,
  InputButtonProps,
} from '~/components/bad/Inputs/Input.type';
import Icon from '~/components/atom/Icon';
import { InputWithTooltip } from '~/components/bad/Inputs/Input';
import { SynchroniseEntityType } from '~/components/page/External/Nylas/components/NylasAuthoriseEndpointV1/types';
import Button from '~/components/atom/Button';
import TextButton from '~/components/atom/TextButton';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { EMAIL_SYNC_TIMEOUT } from '~/components/molecule/ChooseEmailSyncType/constants';

const accountWarning =
  'Door het verwijderen van het e-mailaccount kunnen flows geen e-mail meer versturen. Ook kunnen gekoppelde e-mails niet meer worden bekeken.';
const userAndOfficeWarning =
  'Door het verwijderen kunnen gekoppelde e-mails niet meer worden bekeken.';
const text = {
  inputLabel: 'E-mail',
  synchronizeButtonText: 'synchroniseer nieuw e-mail account',
  addAliasText: 'Alias toevoegen',
  header: 'E-mailintegratie',
  deleteConfirmModalLabels: (entityType: SynchroniseEntityType) => ({
    title: 'Waarschuwing',
    message: `${
      entityType === 'Account' ? accountWarning : userAndOfficeWarning
    }`,
    buttonConfirmTitle: 'Verwijderen',
    buttonCancelTitle: 'Annuleren',
  }),
};

type Props = {
  email: string;
  syncState: EmailSyncStatus;
  onDelete: () => void;
  mayEdit: boolean;
  loading: boolean;

  /** Where this component is from */
  entityType: SynchroniseEntityType;
  entityId: string;
  emailAliasState: EmailAliasStatus | null;
  emailAliasValidatedDate: Date | null;
  initialEmailAlias: string | null;
  refetch: () => Promise<any>;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
};
const SynchronisedMailbox: React.FCC<Props> = ({
  email,
  syncState,
  onDelete,
  loading,
  entityType,
  entityId,
  mayEdit,
  initialEmailAlias,
  emailAliasState,
  emailAliasValidatedDate,
  refetch,
  startPolling,
  stopPolling,
}) => {
  const [triggerConfirmModal] = useConfirmModal({
    labels: text.deleteConfirmModalLabels(entityType),
    onConfirmDefault: onDelete,
    dataTestId: TEST_ID.DELETE_CONFIRM_MODAL,
  });

  const syncInfoButtonProps = getSyncInfoButtonProps({
    syncState,
  });

  return (
    <>
      <InputGroup>
        <InputGroupContainer>
          <StyledInputGroup>
            <InputWithTooltip
              label={text.inputLabel}
              data-testid={TEST_ID.GET_MAILBOX_INPUT(email)}
              disabled={true}
              value={email}
              name={`admin-input-${email}`}
              accent={syncInfoButtonProps.accent}
              button={syncInfoButtonProps}
            />
            <Button
              ghost
              size="medium"
              appearance="danger"
              icon="trashcan"
              dataTestId={TEST_ID.GET_MAILBOX_DELETE_BUTTON(email)}
              onClick={triggerConfirmModal}
              disabled={!(mayEdit === true)}
              loading={loading}
            />
          </StyledInputGroup>
          <AliasComponent
            syncState={syncState}
            mayEdit={mayEdit}
            email={email}
            entityId={entityId}
            entityType={entityType}
            emailAliasState={emailAliasState}
            emailAliasValidatedDate={emailAliasValidatedDate}
            startPolling={startPolling}
            stopPolling={stopPolling}
            refetch={refetch}
            initialEmailAlias={initialEmailAlias}
          />
        </InputGroupContainer>
      </InputGroup>
    </>
  );
};

type AliasComponentProps = {
  email: string;
  syncState: EmailSyncStatus;
  mayEdit: boolean;
  entityId: string;
  entityType: SynchroniseEntityType;
  emailAliasState: EmailAliasStatus | null;
  emailAliasValidatedDate: Date | null;
  initialEmailAlias: string | null;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;
  refetch: () => Promise<any>;
};
const AliasComponent: React.FCC<AliasComponentProps> = ({
  syncState,
  mayEdit,
  email,
  entityId,
  entityType,
  emailAliasState,
  initialEmailAlias,
  emailAliasValidatedDate,
  startPolling,
  stopPolling,
  refetch,
}) => {
  const pollingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [emailAlias, setEmailAlias] = useState<string | null>(
    initialEmailAlias,
  );
  const [isAddingOrExists, setAddingOrExists] = useState(
    initialEmailAlias != null && emailAliasState != null,
  );

  if (!isAddingOrExists)
    return (
      <>
        <JustificationContainer
          gap="xs"
          align="center"
          margin={['m', null, null, null]}
        >
          <TextButton
            withoutPadding
            size="medium"
            label={text.addAliasText}
            disabled={!mayEdit || !isSuccessEmailSyncStatus(syncState)}
            onClick={() => setAddingOrExists(true)}
            dataTestId={TEST_ID.ADD_ALIAS_BUTTON}
          />
          <HelpButton
            link={{
              link: 'https://help.dathuis.nl/nl/articles/3966599-hoe-voeg-ik-een-alias-aan-mijn-e-mailadres-toe',
            }}
          />
        </JustificationContainer>
      </>
    );

  return (
    <LinkedSubField>
      {setRef => (
        <EmailAlias
          parentEmail={email}
          emailAlias={{
            email: emailAlias,
            state: emailAliasState,
            validatedDate: emailAliasValidatedDate,
          }}
          setRef={setRef}
          onChange={newAlias => {
            setEmailAlias(newAlias);
          }}
          onSuccessfulVerificationStart={async ({ emailAlias }) => {
            setEmailAlias(emailAlias);

            await refetch();
            startPolling(3000);

            // stop polling if it is not fixed within a minute
            pollingTimeout.current = setTimeout(
              stopPolling,
              EMAIL_SYNC_TIMEOUT,
            );
          }}
          onSuccessfulDelete={() => {
            setEmailAlias(null);
            setAddingOrExists(false);

            void refetch();
          }}
          entityId={entityId}
          entityType={entityType}
          mayEdit={mayEdit}
        />
      )}
    </LinkedSubField>
  );
};

const getSyncInfoButtonProps = ({
  syncState,
}: {
  syncState: EmailSyncStatus;
}): InputButtonProps & { accent: boolean } => {
  if (isSuccessEmailSyncStatus(syncState)) {
    return {
      icon: <Icon name="check" strokeWidth={2.5} />,
      type: INPUT_BUTTON_TYPE.SUCCESS,
      accent: false,
      tooltipText: null,
    };
  }

  return {
    icon: <Icon name="exclamation" />,
    type: INPUT_BUTTON_TYPE.ACCENT,
    tooltipText: null,
    accent: true,
  };
};

const StyledInputGroup = styled(InputGroup)<{}>`
  margin-top: 0;
  margin-bottom: 0;
`;

const InputGroupContainer = styled.div<{}>`
  width: 100%;
`;

export default SynchronisedMailbox;
