import React from 'react';

import { TableCell } from '~/components/bad/DataTables/types.flow';

import ActionColumnCell, {
  ActionListOption,
} from '~/components/bad/DataTables/components/ActionColumnCell';
import useConfirmModal from '~/hooks/useConfirmModal';
import TEST_ID from './index.testid';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import {
  useCopyFormMutation,
  useDeleteFormMutation,
  useGetFormLazyQuery,
  useUpdateFormMutation,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import deserializeFormBuilder from '~/components/page/Forms/utils/deserializeFormBuilder';
import Catalog from '~/Catalog';
import useErrorReporter from '~/hooks/useErrorReporter';
import Icon from '~/components/atom/Icon';
import useTooltipLayer from '~/hooks/useTooltipLayer';
import cleanedFilename from '~/util/cleanedFilename';
import { useTheme } from 'styled-components';
import { navigate } from '@gatsbyjs/reach-router';

const text = {
  deactivateLabel: 'Deactiveer',
  activateLabel: 'Activeer',
  copyLabel: 'Kopieer formulier',
  deleteLabel: 'Verwijderen',
  deleteErrorMessage:
    'Er is iets misgegaan bij het verwijderen van het formulier. Probeer het nog eens.',
  confirmModalCancelButtonText: 'Annuleren',
  confirmDeleteTitle: 'Formulier verwijderen?',
  confirmDeleteButton: 'Verwijderen',
  confirmDeleteMessage: 'Delete confirm message',
  deleteToast: 'Formulier verwijderd',
  noDataError: 'No data recieved',
  brokenFormError:
    'Er is iets misgegaan met dit formulier, neem contact met ons op via de chat rechts onderin.',
};

const confirmDeleteModalText = {
  title: text.confirmDeleteTitle,
  buttonConfirmTitle: text.confirmDeleteButton,
  buttonCancelTitle: text.confirmModalCancelButtonText,
};

type Props = {
  id: string;
  cell: TableCell<any>;
  enabled: boolean;
  name: string;
  onSuccessfulDelete: () => void;
  description?: string;
};
const FormActionColumnCell: React.FC<Props> = ({
  id: id,
  cell,
  enabled,
  onSuccessfulDelete,
}) => {
  const theme = useTheme();
  const addToast = useAddToast();
  const [deleteForm] = useDeleteFormMutation();
  const account = useCurrentAccount();
  const reporter = useErrorReporter();
  const [getForm, { loading, error }] = useGetFormLazyQuery({
    variables: { formBuilderId: id, accountId: account.id },
  });
  const [updateForm] = useUpdateFormMutation();
  const tooltipProps = useTooltipLayer({
    tooltipMessage: text.brokenFormError,
  });

  const [copyForm, { loading: copyFormLoading }] = useCopyFormMutation();
  const [showDeleteConfirmModal, setOnDeleteConfirmFunction] = useConfirmModal({
    labels: {
      ...confirmDeleteModalText,
      message: text.confirmDeleteMessage,
    },
    dataTestId: TEST_ID.CONFIRM_MODAL,
    actionType: 'destructive',
  });

  const reportError = (_error: string) => {
    reporter.captureMessage(
      `${cleanedFilename(__filename)}>>${id}>>${_error}`,
      'error',
    );
  };

  const toggleFormEnabled = {
    label: enabled ? text.deactivateLabel : text.activateLabel,
    key: 'update-status',
    onClick: async () => {
      const { data: queryData, error: queryError } = await getForm({
        variables: { formBuilderId: id, accountId: account.id },
      });

      if (!queryData) {
        return reportError(text.noDataError);
      }

      if (queryError) {
        return reportError(queryError.message);
      }

      const formBuilderInput = deserializeFormBuilder(queryData.getForm);

      void updateForm({
        variables: {
          accountId: account.id,
          formBuilderId: id,
          formBuilder: {
            ...formBuilderInput,
            enabled: !enabled,
          },
        },
      }).then(({ data, errors }) => {
        if (errors && errors.length > 0) {
          reportError(JSON.stringify(errors));
          return;
        }

        if (data) {
          addToast([
            formatToastMessage(Catalog.genericSuccessMessage, 'success'),
          ]);
        }
      });
    },
  };

  const options: Array<ActionListOption> = [
    toggleFormEnabled,
    {
      label: text.copyLabel,
      onClick: async (_rowId: string) => {
        const { data, errors } = await copyForm({
          variables: { accountId: account.id, formBuilderId: id },
        });

        if (errors && errors.length > 0) {
          reporter.captureMessage(
            `An error occured while copying the form ${id}. ${JSON.stringify(errors)}`,
            'error',
          );
          addToast([
            formatToastMessage(Catalog.genericUnknownErrorMessage, 'danger'),
          ]);

          return;
        }

        if (data) {
          const copiedFormName = data.copyForm.name;

          return navigate(
            `/-/forms/${data.copyForm.id}?name=${encodeURIComponent(
              copiedFormName,
            )}&description=${encodeURIComponent(data.copyForm.description || '')}`,
          );
        }
      },
      key: 'copy',
    },
    {
      label: text.deleteLabel,
      onClick: () => {
        const deleteFn = () =>
          deleteForm({
            variables: {
              accountId: account.id,
              formBuilderId: id,
            },
          }).then(({ errors }) => {
            if (errors && errors.length !== 0) {
              return addToast([
                formatToastMessage(text.deleteErrorMessage, 'danger'),
              ]);
            }

            onSuccessfulDelete();
            return addToast([formatToastMessage(text.deleteToast, 'success')]);
          });
        setOnDeleteConfirmFunction(deleteFn);
        showDeleteConfirmModal();
      },
      key: 'delete',
      type: 'DELETE',
    },
  ];

  // Not very likely to happen but gives us feedback if the form is broken
  if (error) {
    return (
      <Icon
        name="error"
        color={theme.color('danger')}
        margin={[null, 'm', null]}
        {...tooltipProps}
      />
    );
  }

  return (
    <ActionColumnCell
      key={'contact-action-column-cell'}
      loading={loading || copyFormLoading}
      options={options}
      cell={cell}
    />
  );
};

export default FormActionColumnCell;
