import type { Appearance } from '~/components/atom/Button';
import type { ActionType } from '../..';

const actionTypeToButtonAppearanceMap: { [key in ActionType]: Appearance } = {
  destructive: 'danger',
  success: 'secondary',
  normal: 'primary',
};

const getButtonAppearance = (actionType: ActionType) =>
  actionTypeToButtonAppearanceMap[actionType];

export default getButtonAppearance;
