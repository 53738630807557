import type {
  FormBuilder_OptionList,
  FormBuilder_ScreenNode,
} from '~/graphql/types';
import type { Block, ScreenNode, SelectComponentType } from './types';
import { isNil } from 'ramda';

const LABEL_FALLBACK_EN = 'Please provide a label';
const LABEL_FALLBACK_NL = 'Voer een label in';

const formatNode = (
  node: FormBuilder_ScreenNode,
  optionLists: Array<FormBuilder_OptionList>,
): ScreenNode => ({
  blocks: node.blocks
    .map((block): Block | null => {
      switch (block.__typename) {
        case 'FormData_Input_Contact_Email':
          return {
            key: block.key,
            component: {
              type: 'Input',
              props: {
                label: {
                  EN: block.label?.en ?? LABEL_FALLBACK_EN,
                  NL: block.label?.nl ?? LABEL_FALLBACK_NL,
                },

                type: 'email',
              },
            },
          };
        case 'FormData_Input_Contact_Name':
          return {
            key: block.key,
            component: {
              type: 'Input',
              props: {
                label: {
                  EN: block.label?.en ?? LABEL_FALLBACK_EN,
                  NL: block.label?.nl ?? LABEL_FALLBACK_NL,
                },

                type: 'text',
              },
            },
          };
        case 'FormData_Input_Contact_Phone':
          return {
            key: block.key,
            component: {
              type: 'Input',
              props: {
                label: {
                  EN: block.label?.en ?? LABEL_FALLBACK_EN,
                  NL: block.label?.nl ?? LABEL_FALLBACK_NL,
                },

                type: 'text',
              },
            },
          };

        case 'FormData_Input_Email':
          return {
            key: block.key,
            component: {
              type: 'Input',
              props: {
                label: {
                  EN: block.label?.en ?? LABEL_FALLBACK_EN,
                  NL: block.label?.nl ?? LABEL_FALLBACK_NL,
                },

                type: 'email',
              },
            },
          };
        case 'FormData_Input_Integer':
          return {
            key: block.key,
            component: {
              type: 'Input',
              props: {
                label: {
                  EN: block.label?.en ?? LABEL_FALLBACK_EN,
                  NL: block.label?.nl ?? LABEL_FALLBACK_NL,
                },
                type: 'int',
              },
            },
          };
        case 'FormData_Input_Multiline':
          return {
            key: block.key,
            component: {
              type: 'Input',
              props: {
                label: {
                  EN: block.label?.en ?? LABEL_FALLBACK_EN,
                  NL: block.label?.nl ?? LABEL_FALLBACK_NL,
                },
                type: 'multi-line',
              },
            },
          };

        case 'FormData_Input_Text':
          return {
            key: block.key,
            component: {
              type: 'Input',
              props: {
                label: {
                  EN: block.label?.en ?? LABEL_FALLBACK_EN,
                  NL: block.label?.nl ?? LABEL_FALLBACK_NL,
                },
                type: 'text',
              },
            },
          };

        case 'FormData_Select_Dropdown':
        case 'FormData_Select_MultiButton':
        case 'FormData_Select_Radio': {
          return {
            key: block.key,
            component: {
              type: 'Select',
              props: {
                type: selectTypeMap[block.__typename],
                label: {
                  EN: block.label?.en ?? LABEL_FALLBACK_EN,
                  NL: block.label?.nl ?? LABEL_FALLBACK_NL,
                },
                options: formatOptions(block.optionListId, optionLists),
              },
            },
          };
        }
        case 'FormData_UI_Typography':
          return {
            key: block.key,
            component: {
              type: 'Typography',
              props: {
                type: block.type ?? 'Body',

                alignment: block.alignment,
                content: {
                  NL: block.content.nl ?? LABEL_FALLBACK_NL,
                  EN: block.content.en ?? LABEL_FALLBACK_EN,
                },
              },
            },
          };
        case 'FormData_UI_Image':
          return {
            key: block.key,
            component: {
              type: 'Image',
              props: {
                alignment: block.alignment,
                alt: {
                  EN: block.alt.nl ?? LABEL_FALLBACK_EN,
                  NL: block.alt.nl ?? LABEL_FALLBACK_NL,
                },
                src: block.image.url,
                width: block.width ?? 800,
              },
            },
          };
        case 'FormData_UI_RichText':
          return {
            key: block.key,
            component: {
              type: 'RichText',
              props: {
                alignment: block.alignment,
                content: {
                  EN: block.content?.en ?? LABEL_FALLBACK_EN,
                  NL: block.content?.nl ?? LABEL_FALLBACK_NL,
                },
              },
            },
          };
        default:
          return null;
      }
    })
    .filter((x): x is Block => !isNil(x)),
  id: node.id,
  defaultNext: node.defaultNext,
});

const selectTypeMap: Record<
  | 'FormData_Select_Dropdown'
  | 'FormData_Select_MultiButton'
  | 'FormData_Select_Radio',
  'dropdown' | 'multi-button' | 'radio'
> = {
  FormData_Select_Dropdown: 'dropdown',
  FormData_Select_MultiButton: 'multi-button',
  FormData_Select_Radio: 'radio',
};

export const formatOptions = (
  id: string,
  optionLists: Array<FormBuilder_OptionList>,
): SelectComponentType['props']['options'] =>
  (optionLists.find(list => list.id === id)?.options ?? []).map(
    ({
      key,
      label,
      icon,
    }): SelectComponentType['props']['options'][number] => ({
      label: {
        EN: label?.en ?? LABEL_FALLBACK_EN,
        NL: label?.nl ?? LABEL_FALLBACK_NL,
      },
      payload: key,
      icon: icon ?? undefined,
    }),
  );

export default formatNode;
