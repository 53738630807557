import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import DropdownListContainer from '~/components/molecule/Dropdown/components/DropdownListContainer';
import getMenuOptions, { type ModalProps } from './utils/getMenuOptions';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import Dialog from '~/components/organism/ModalsV2/Dialog';
import { Body } from '~/components/atom/Typography';
import type { FormBuilder_Node } from '~/graphql/types';
import getStartNode from '../../utils/getStartNode';
import Catalog from '~/Catalog';
import { nodesSelector } from '../../state/nodesAndEvents';

const text = {
  modalHeader: 'Pas op!',
  modalBody: 'Weet je het zeker?',
};

export type Props = {
  nodeId: string;
  nodeType: FormBuilder_Node['__typename'];
  isSubmitScreenNode?: boolean;
};

const defaultModalState: ModalProps = {
  shown: false,
  header: '',
  body: '',
  onConfirm: () => {},
};

const CardMenu: React.FCC<Props> = ({
  nodeId,
  nodeType,
  isSubmitScreenNode,
}) => {
  const nodes = useRecoilValue(nodesSelector);
  const [showDropdown, setShowDropdown] = useState(false);
  const [modalState, setModalState] = useState(defaultModalState);

  const [nodesState, setNodesState] = useRecoilState(nodesSelector);

  const dropdownOptions = getMenuOptions({
    nodeType,
    isStartNode: getStartNode(nodesState).id === nodeId,
    setNodesState,
    isSubmitScreenNode,
    screenNodesLength: nodes.filter(
      n => n.__typename === 'FormBuilder_ScreenNode',
    ).length,
  });

  if (dropdownOptions.length === 0) return null;

  return (
    <>
      <Container
        onClick={e => {
          e.stopPropagation();
          setShowDropdown(true);
        }}
        justification="center"
        align="center"
      >
        <Icon name="dot-menu" />

        {showDropdown && (
          <StyledDropdown
            onChange={v => {
              v.option.payload({
                nodeId,
                setModalOptions: modalProps => {
                  setModalState(modalProps);
                },
              });

              setShowDropdown(false);
            }}
            dropdownListOpen
            onClickOutside={() => setShowDropdown(false)}
            onClose={() => setShowDropdown(false)}
            options={dropdownOptions}
          />
        )}
      </Container>

      {modalState.shown && (
        <Overlay onClose={() => setModalState(defaultModalState)}>
          <Dialog
            header={modalState.header || text.modalHeader}
            body={<Body>{modalState.body || text.modalBody}</Body>}
            onConfirm={() => {
              modalState.onConfirm();
              setModalState(defaultModalState);
            }}
            confirmAction={{
              label: Catalog.confirm,
            }}
            cancelAction={{
              label: Catalog.cancel,
            }}
          />
        </Overlay>
      )}
    </>
  );
};

const Container = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.space('xxs')};
    right: ${theme.space('xxs')};
    padding: ${theme.space('xxs')};
    border-radius: ${theme.getTokens().border.radius.s};
    transition: all 0.3s ease-out;
    cursor: pointer;

    color: ${theme.color('grey', 'dark')};

    &:hover {
      background-color: ${theme.color('grey', 'light')};
      color: ${theme.color('text')};
    }
  `,
);

const StyledDropdown = styled(DropdownListContainer)<{}>(
  ({ theme }) => css`
    right: 0;
    top: ${theme.space('xl')};
  `,
);

export default CardMenu;
