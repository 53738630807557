import React, { useState } from 'react';

import { Heading1 } from '~/components/atom/Typography';
import type { EditProps } from '../..';
import type { FormBuilder_ScreenNode } from '~/graphql/types';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Button from '~/components/atom/Button';
import Catalog from '~/Catalog';
import TextButton from '~/components/atom/TextButton';
import Input from '~/components/molecule/Input';
import TipBanner from '~/components/organism/TipBanner';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { interactionState } from '../../../../state';
import { nodesSelector } from '../../../../state/nodesAndEvents';
import insertNode from '../../../../utils/insertNode';
import hasValue from '~/util/hasValue';
import useKeybinding from '~/hooks/useKeybinding';

export type ScreenFormState = FormBuilder_ScreenNode;
export type Props = EditProps<ScreenFormState>;

const text = {
  header: 'Nieuwe pagina toevoegen',
  submitLabel: 'Pagina maken',
  inputLabel: 'Naam van de nieuwe pagina',
  inputPlaceholder: 'bijv. Welkom pagina',

  tipHeader: 'Waarom heeft een pagina een naam nodig?',
  tipBody: (
    <>
      De namen van de schermen komen terug op meerdere plekken waaronder de
      Contact filters en de Flow conditions. Deze namen helpen jou makelijk
      indentificeren met welke waardes je aan de slag bent.
      <br />
      <br />
      <strong>Bijvoorbeeld:</strong> in de Contact filters als het scherm
      <em>&quot;Welkom pagina&quot;</em> heet zullen de waardes van dat scherm
      het voorvoegsel <em>&quot;Welkom pagina&quot;</em> krijgen
      <br />
      <br />
      Dit kan later aangepast worden via de pagina instellingen.
    </>
  ),
};

const CreateScreenNode: React.FC<Props> = ({ onCancel }) => {
  // Not using the passed onSave as that would trigger some undesired behavior
  const [interaction, setInteraction] = useRecoilState(interactionState);
  const setNodesState = useSetRecoilState(nodesSelector);
  const [name, setName] = useState('');

  const onSave = () => {
    // Will always be true otherwise we wouldn't see this screen but Typescript doesn't know that
    if (interaction?.interactionType === 'create-node-screen') {
      setNodesState(prev =>
        insertNode({
          createType: 'screen',
          relation: interaction.relation,
          prevNodes: prev,
          name,
        }),
      );
      setInteraction(null);
    }
  };

  useKeybinding({
    keys: 'enter',
    callback: hasValue(name) ? onSave : () => {},
  });

  return (
    <JustificationContainer
      direction="column"
      gap="m"
      padding={['xl']}
      style={{ flexGrow: 1 }}
      width="100%"
    >
      <Heading1 margin={[null]}>{text.header}</Heading1>
      <TipBanner
        dismissible={false}
        headerText={text.tipHeader}
        id="name-screen-tip"
        margin={[null]}
      >
        {text.tipBody}
      </TipBanner>
      <Input
        required
        width="100%"
        label={text.inputLabel}
        placeholder={text.inputPlaceholder}
        value={name}
        onChange={event => {
          setName(event.target.value);
        }}
        onKeyDown={event => {
          if (event.key === 'Enter' && hasValue(name)) {
            event.preventDefault();
            onSave();
          }
        }}
      />

      <JustificationContainer justification="space-between" width="100%">
        <TextButton
          label={Catalog.cancel}
          appearance="danger"
          onClick={onCancel}
        />
        <Button
          label={text.submitLabel}
          appearance="primary"
          disabled={!hasValue(name)}
          onClick={onSave}
        />
      </JustificationContainer>
    </JustificationContainer>
  );
};

export default CreateScreenNode;
