import React from 'react';
import styled, { css } from 'styled-components';
import Icon, { IconType } from '~/components/atom/Icon';
import JustificationContainer, {
  Props as JustificationProps,
} from '~/components/atom/JustificationContainer';
import { BaseColor } from '~/theme/System/tokens';

export type Level = 'success' | 'warning' | 'danger' | 'loading';

export type Props = JustificationProps & {
  /** Status of the event */
  level?: Level | null;

  /** Label to describe the status */
  label?: React.ReactNode;
};

/**
 * Used to display the status of various processes or events (e.g. EmailSyncStatus, EmailDeliveryStatus etc.)
 */
const StatusContainer: React.FCC<Props> = ({
  dataTestId,
  children,
  level,
  label,
  padding = ['xs'],
  ...rest
}) => {
  const group = getGroupForLevel(level);
  const iconName = getIconNameForLevel(level);

  return (
    <Container
      data-testid={dataTestId}
      data-level={level}
      width="100%"
      padding={padding}
      $color={group}
      {...rest}
    >
      <JustificationContainer padding={['xxxs', null]}>
        {iconName && <Icon name={iconName} margin={[null, 'xs', null, null]} />}
        {label && <div>{label}</div>}
      </JustificationContainer>

      {children}
    </Container>
  );
};

const Container = styled(JustificationContainer)<{ $color: BaseColor }>(
  ({ theme, $color }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
    color: ${theme.color($color)};
    background-color: ${theme.color($color, 'translucent')};
  `,
);

const getGroupForLevel = (level?: Level | null): BaseColor => {
  if (!level || level === 'loading') return 'grey';
  return level;
};

const getIconNameForLevel = (level?: Level | null): IconType | null => {
  switch (level) {
    case 'loading':
      return 'spinner';
    case 'danger':
      return 'error';
    case 'warning':
      return 'exclamation';
    case 'success':
      return 'check';
    default:
      return null;
  }
};

export default StatusContainer;
